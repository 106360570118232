import cellsimPortalService from '@/api/cellSim.js';

export default {
  create(data) {
    return cellsimPortalService
      .Api().post(`billPayment`, data)
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },
  getCarrier() {
    return cellsimPortalService
      .Api().get(`carrier`)
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },
  getAll(params) {
    return cellsimPortalService
      .Api().get(`billPayment`, {params} )
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },
  getById(id) {
    return cellsimPortalService
      .Api().get(`billPayment/${id}`)
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },

}
