const Index = () => import('../index.vue');
const Login = () => import('@/modules/Authentication/components/Login.vue');
const Logout = () => import('@/modules/Authentication/components/Logout.vue');
const CreateAccount = () => import('@/modules/Authentication/components/CreateAccount.vue');
const WebTokenAuthentication = () => import('@/modules/Authentication/components/WebTokenAuthentication.vue');


export default [
  {
    path: '/login',
    component: Index,
    title: 'Login',
    children: [
      {
        path: '/webauthenticator',
        name: 'authentication-web-token',
        component: WebTokenAuthentication,
        props: true,
      },
      {
        path: '/',
        name: 'authentication-login',
        component: Login,
        props: true,
      },
      {
        path: '/create/account',
        name: 'create-account',
        component: CreateAccount,
      },
    ],
  },
  {
    path: '/logout',
    name: 'authentication-logout',
    component: Logout,
  },
];
