// import organizationSerivce from '@/modules/Organization/service.js'

export default {
    namespaced: true,
    state: {
        Contact: null,
        balance: 0,
        contactNumber: {
            email: 'mobilesolutionsgroupinc@gmail.com',
            mailto: 'mailto:mobilesolutionsgroupinc@gmail.com',
            number: 'tel: 9179631863',
            label: '(917) 963-1863',
            name: 'Test'
        }
    },
    getters: {
        getContactNumber(state) {
            return state.contactNumber;
        },
        getContact(state) {
            return state.Contact;
        },
        getBalance(state) {
            return state.balance;
        },
    },
    actions: {
        setContact({
            commit
        }, payload) {
            commit('setContact', payload);
        },
        setBalance({
            commit
        }, payload) {
            commit('setBalance', payload);
        },
    },
    mutations: {
        setContact(state, payload) {
            state.Contact = payload;
        },
        setBalance(state, payload) {
            state.balance = payload;
        },
    }
}