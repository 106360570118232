import axios from 'axios';
import { cacheAdapterEnhancer } from 'axios-extensions';

export default {
    baseURL() {
        return `${process.env.VUE_APP_SERVER_URL}/cellSim/`
    },
    /**
    * Make an api call to the server. Token in local storage is required
    */
    Api(token) {
        const instance = axios.create({
            baseURL: `${process.env.VUE_APP_SERVER_URL}/cellSim`,
            withCredentials: false,
            headers: {
                'x-clientid': process.env.VUE_APP_SERVER_API_KEY,
                token: token || localStorage.getItem(`${process.env.VUE_APP_STORAGE_PREFIX}token`),
                'Cache-Control': 'no-cache',
                'x-timezone': localStorage.getItem(`${process.env.VUE_APP_STORAGE_PREFIX}timezone`),
            },
            adapter: cacheAdapterEnhancer(axios.defaults.adapter),
        });

        // Request Interceptor
        instance.interceptors.request.use(
            config =>
                // Do something before request is sent
                config,
            error => Promise.reject(error),
        );

        // Response interceptor
        instance.interceptors.response.use(
            response => // if (process.env.NODE_ENV === 'development') console.log(response.data); // eslint-disable-line       
                response,
            error => {
                if (error.response) {
                    if (error.response.status === 401) {
                        console.log('token was remove by api ')
                        localStorage.removeItem(`${process.env.VUE_APP_STORAGE_PREFIX}token`);
                    }
                    return Promise.reject(error.response);
                }
                return Promise.reject(error)
            },
        );
        return instance;
    },
    /**
    * Check if server is available
    */
    ping() {
        return this.Api()
        .get('')
        .then(response => response.status === 200)
        .catch(error => {
            throw error;
        });
    },
}