/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */

import Vue from 'vue'
import VueRouter from 'vue-router'
import historyRoutes from '@/modules/History/router';
import loginRoutes from '@/modules/Authentication/router';
import Home from '@/modules/Home/Home.vue';
const Main = () => import('@/modules/Layout/index.vue');
const PageNotFound = () => import('@/modules/../components/PageNotFound.vue');
const Status = () => import('@/modules/Status/index.vue');
// adding these routers inside this array
let childrenRoutes = [];
childrenRoutes = childrenRoutes.concat(historyRoutes)
childrenRoutes = childrenRoutes.concat([
	{
		path: 'home',
		name: 'home',
		component: Home,
	},
])

Vue.use(VueRouter)
let routes = [{
    path: '/',
    name: 'main',
    children: childrenRoutes,
    component: Main,
    redirect: { path: '/home' },
    beforeEnter: ((to, from, next) => {
      if (localStorage.getItem(`${process.env.VUE_APP_STORAGE_PREFIX}token`) == null) {
        next({
          path: '/login',
          params: {
            nextUrl: to.fullPath
          },
        });
      } else if (localStorage.getItem(`${process.env.VUE_APP_STORAGE_PREFIX}webActivationToken`) == null) {
				next({
          path: '/webauthenticator',
          params: {
            nextUrl: to.fullPath
          },
        });
			}else {
        console.log('allowed to proceed')
        next()
      }
    })
  },
  {
    path: '/status',
    name: 'status',
    component: Status,
  },
  {
    path: '*',
    name: 'pagenotfound',
    component: PageNotFound,
  },

]
routes = routes.concat(loginRoutes);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})




export default router