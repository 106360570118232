<template>
  <v-layout row align-center>
    <v-flex shrink>
      <v-text-field v-model="search" class="rounded-l-xl" @keydown.enter="setValue()" label="Search" ref="search"
        @click:append="setNull()" single-line outlined dense hide-details />
    </v-flex>
    <v-flex shrink>
      <v-btn color="teal accent-4 rounded-r-xl" dark @click="setValue()">Search</v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  props: ['hideBackBtn', 'hideSearchBar'],
  data() {
    return {
      search: null,
    }
  },
  watch: {
    search(val) {
      // console.log('val', val.length)
      if (val.length === 0) {
        this.setValue()
      }
    }
  },
  created() {
    setTimeout(() => {
      if (this.$refs['search']) {
        this.$refs['search'].$refs.input.focus();
      }
    }, 200);
  },
  methods: {
    setValue() {
      this.$emit('input', this.search)
    },
    setNull() {
      this.$emit('input', '')
      this.search = ''
    },
  },
};
</script>

<style scoped></style>
