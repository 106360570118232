<template>
  <div>
    <v-data-table v-if="sims.length !== 0" class="mt-3 table-header" hide-default-footer :headers="headers" :items="sims"
      :loading="loading" :items-per-page="sims.length">
      <template v-slot:item="{ item }">
        <tr class="pointer" @click="openDetail(item)">
          <td>
            <span>{{ item.createdAt | moment('MM/DD/YYYY LT') }}</span>
            <span class="ml-2" v-if="item.metadata && item.metadata.simInfo && item.metadata.simInfo.eSim">
              <v-chip small pill class="font-weight-bold">E-SIM</v-chip>
            </span>
          </td>
          <td>
            <v-chip class="font-weight-bold" label color="primary" v-if="item.activationType === 'parent'">
              <v-icon left>mdi-family-tree</v-icon>
              Family
            </v-chip>
          </td>
          <td>
            <div>{{ item.serialNumber | simNumber }}</div>
            <div v-if="item.plan">{{ item.plan }}</div>
          </td>
          <td v-if="item.metadata
            && item.metadata.activated
            && item.metadata.activated.phoneNumber">{{ item.metadata.activated.phoneNumber | USPhoneNumber }}
          </td>
          <td v-else>
            <v-chip v-if="item.status !== 'rejected'" small label>Processing...</v-chip>
            <v-chip v-else small color="red" text-color="white" label>Rejected</v-chip>
          </td>
          <td v-if="item.metadata
            && item.metadata.simInfo
            && item.metadata.simInfo.activation">{{ item.metadata.simInfo.activation }}</td>
          <td v-else>N/A</td>
          <td>
            <v-chip class="text-capitalize" small label dark :color="color(item.status)">
              {{ (item.status === 'activate') ? 'pending' : item.status }}
            </v-chip>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-dialog v-model="detailDialog" width="500" v-if="selectedPlan">

      <v-card class="rounded-xl" elevation="15">
        <v-card-title class="text-h5 black--text font-weight-bold">
          <v-chip class="font-weight-bold mr-1" label color="primary" v-if="selectedPlan.activationType === 'parent'">
            <v-icon left>mdi-family-tree</v-icon>
            Family
          </v-chip> {{ title }}
          <v-spacer></v-spacer>
          <v-btn text icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-container grid-list-md>
          <v-layout row wrap class="mx-2" align-center>
            <v-flex xs12>
              <span class="font-weight-bold">Sim #</span> <span>{{ selectedPlan.serialNumber | simNumber }}</span>
            </v-flex>
            <v-flex xs12 v-if="selectedPlan.metadata
              && selectedPlan.metadata.activated
              && selectedPlan.metadata.activated.phoneNumber">
              <span class="font-weight-bold">Phone #</span> <span>{{ selectedPlan.metadata.activated.phoneNumber |
                USPhoneNumber }}</span>
            </v-flex>
            <v-flex xs12 v-else>
              <span class="font-weight-bold">Phone #</span>
              <v-chip v-if="selectedPlan.status !== 'rejected'" small label>Processing...</v-chip>
              <v-chip v-else small color="red" text-color="white" label>Rejected</v-chip>
            </v-flex>
            <v-flex xs12>
              <v-data-table v-if="selectedLine" hide-default-footer :headers="planHeaders" :items="selectedLine.data"
                :loading="loading" :items-per-page="selectedLine.data.length">
                <template v-slot:item="{ item }">
                  <tr class="pointer">
                    <td>
                      <div>{{ item.serialNumber | simNumber }}</div>
                      <div v-if="item.plan">{{ item.plan }}</div>
                    </td>
                    <td v-if="item.metadata
                      && item.metadata.activated
                      && item.metadata.activated.phoneNumber">{{
    item.metadata.activated.phoneNumber | USPhoneNumber }}
                    </td>
                    <td v-else>
                      <v-chip v-if="item.status !== 'rejected'" small label>Processing...</v-chip>
                      <v-chip v-else small color="red" text-color="white" label>Rejected</v-chip>
                    </td>
                  </tr>
                </template>
              </v-data-table>

              <div class="text-center" v-else>
                <v-progress-circular indeterminate :size="70" :width="7"></v-progress-circular>
                <div>Getting Other Number</div>
              </div>
            </v-flex>
          </v-layout>
        </v-container>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="detailDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>


  <!-- <div v-else class="text-h5 font-weight-black text-center v-window__container center">
    You have activate any sim yet!
  </div> -->
</template>

<script>
import activationService from "@/modules/Activation/service.js";
import EventBus from '@/event-bus.js';

export default {
  name: 'activation-list',
  data() {
    return {
      selectedPlan: null,
      selectedLine: null,
      title: '',
      detailDialog: false,
      loading: false,
      sims: [],
      limit: 10,
      orderBy: ['createdAt', 'true'],
      planHeaders: [
        {
          text: "Sim Number",
          align: "left",
          value: "serialNumber",
          sortable: true,
        },
        {
          text: "Phone Number",
          value: "metadata.activated.phoneNumber",
          sortable: true,
        },
      ],
      headers: [
        {
          text: 'Date',
          align: "left",
          value: "createdAt",
          sortable: true,
        },
        {
          text: '',
          align: "center",
          width: '30px',
        },
        {
          text: "Sim Number",
          align: "left",
          value: "serialNumber",
          sortable: true,
        },
        {
          text: "Phone Number",
          value: "metadata.activated.phoneNumber",
          sortable: true,
        },
        {
          text: 'Activation Type',
          value: "item.metadata.simInfo.activation",
          sortable: true,
        },
        {
          text: "Status",
          value: "action",
          sortable: false,
        },
      ],
      contact: JSON.parse(localStorage.getItem(
        `${process.env.VUE_APP_STORAGE_PREFIX}contact`
      ))
    }
  },
  created() {
    this.getAll()
    EventBus.$on('reload-activation-list', () => {
      //console.log('bboomdasf')
      this.getAll()
    })
  },
  beforeDestroy() {
    EventBus.$off('reload-activation-list')
  },
  methods: {
    close() {
      this.detailDialog = false
      this.selectedLine = null
      this.selectedPlan = null
    },
    async openDetail(obj) {
      this.title = obj.plan
      this.selectedPlan = obj
      // this.detailDetail(obj.id)
      this.selectedLine = await activationService.getByParentId(obj.id)
      this.detailDialog = !this.detailDialog
    },
    // async detailDetail(id) {
    //   console.log('openDetailopenDetail', this.selectedPlan)
    //   this.selectedLine = await activationService.getByParentId(id)
    //   console.log('getByParentIdgetByParentIdget', this.selectedLine)
    // },
    color(val) {
      let value = null
      switch (val) {
        case 'activated':
          value = 'teal accent-4'
          break;
        case 'paid':
          value = 'teal accent-4'
          break;
        case 'pending':
          value = 'blue-grey darken-1'
          break;
        case 'open':
          value = 'blue-grey darken-1'
          break;
        case 'closed':
          value = 'green'
          break;
        case 'rejected':
          value = 'red darken-1'
          break;
        // default:
        // value = 'grey'
        // 	break;
      }
      // console.log('value', value, val)
      return value
    },
    async getAll() {
      try {
        this.loading = true
        let response = await activationService.getAll({
          limit: this.limit,
          orderBy: this.orderBy,
          contactId: this.contact.id,
          hideChild: true
        })
        console.log('activation list', response);
        this.sims = response.data
        // this.sims = []
        // console.log('response.data is :', response.data)
        this.loading = false
      } catch (error) {
        this.loading = false
        console.log('erir is :', error)
        // this.$swal('error occured');
      }
    },
  }
}
</script>

<style scoped></style>