import cellsimPortalService from '@/api/cellSim.js';

export default {
  // BillPayment: {
  //   create(data) {
  //     return cellsimPortalService
  //       .Api().post(`billPayment`, data)
  //       .then(response => response.data)
  //       .catch((error) => {
  //         throw error
  //       })
  //   },
  //   getCarrier() {
  //     return cellsimPortalService
  //       .Api().get(`carrier`)
  //       .then(response => response.data)
  //       .catch((error) => {
  //         throw error
  //       })
  //   },
  //   getAll(params) {
  //     return cellsimPortalService
  //       .Api().get(`billPayment`, {params} )
  //       .then(response => response.data)
  //       .catch((error) => {
  //         throw error
  //       })
  //   },
  // },
  Customer: {
    update(id, data) {
      return cellsimPortalService
        .Api().put(`customer/${id}`, data )
        .then(response => response.data)
        .catch((error) => {
          throw error
        })
    },
    getById(id, params) {
      return cellsimPortalService
        .Api().get(`customer/${id}`, {params} )
        .then(response => response.data)
        .catch((error) => {
          throw error
        })
    },
  },
  emitCustomEvent(data) {
    return cellsimPortalService
      .Api()
      .post('socket/emit', data)
      .then(response => response.data)
      .catch(error => {
        throw error;
      });
  },
  /**
   *  Get token from server
   * @param {} id
   * @param {*} pin
   * @param {*} locationCode
   * @param {*} deviceCode
   * @param {*} deviceName
   */
  getSimInfo(number, params) {
    return cellsimPortalService
      .Api().get(`serialVault/serialNumber/${number}`, {
        params
      })
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },
  createFamilyPlan(data) {
    return cellsimPortalService
      .Api().post(`serialVault/createFamilyPlan`, data)
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },
  markActive(data) {
    return cellsimPortalService
      .Api().post(`serialVault/markActive`, data)
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },
  getSettings(params) {
    return cellsimPortalService
      .Api().get(`setting/integration`, {
        params
      })
      .then(response => {
        console.log('response', response)
        if (response.data.data[0].IntegrationSettings && response.data.data[0].IntegrationSettings.metadata) {
          return {
            plan: response.data.data[0].IntegrationSettings.metadata.prePayActivationPlans,
            hotSpotPlan: response.data.data[0].IntegrationSettings.metadata.prePayActivationHotSpotPlan,
            setting: response.data.data[0].IntegrationSettings.metadata,
            product: response.data.data[0].IntegrationSettings.metadata.product,
            EmployeeId: response.data.data[0].IntegrationSettings.metadata.EmployeeId,
            CashDrawerId: response.data.data[0].IntegrationSettings.metadata.CashDrawerId,
          }
        } else {
          return null
        }
      })
      .catch((error) => {
        throw error
      })
  },
  randomString(length, chars) {
    let result = '';
    for (let i = length; i > 0; --i) {
      result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
  },
};

/**
 * skeleton for invoice
 * {
  "description": "Testing the purchase",
  "discount": 0,
   "ContactId":1,
  "InvoiceDetails": [
    {
      "InvoiceDetailTypeId": 1,
      "ProductId": 31,
      "name": "Iphone Case $30",
      "quantity":2,
      "cost": 15.00,
      "price": 30.00,
      "discount":2.5,
      "surchargeTotal" : 8
    },
    {
      "InvoiceDetailTypeId": 1,
      "ProductId": 45,
      "name": "Iphone Case $10",
      "quantity":1,
      "cost": 5.00,
      "price": 10.00,
      "discount": 2
     
    }
  ],
  "Payments":[
      {
        "PaymentMethodId":1,
       "surchargeTotal" : 8,
        "amount":63,
        "tender":100.00
      }
    ]
} */