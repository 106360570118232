<template>
  <div>
    <v-card width="200" @click="dialog = !dialog" v-if="!$vuetify.breakpoint.xsOnly" :ripple="false" height="200"
      class="button-30 rounded-xl ">
      <v-card-text style="height: 100%">
        <v-layout column fill-height align-center>
          <v-flex shrink>
            <v-img height="100" contain src="@/assets/family.png"></v-img>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex class="text-h6 black--text font-weight-bold">
            Family Plan
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
    <v-card v-else width="100%" @click="dialog = !dialog" :ripple="false" height="85" class="button-30 rounded-xl">
      <v-card-text style="height: 100%">
        <v-row align="center">
          <v-col class="shrink">
            <v-img width="50" contain src="@/assets/family.png"></v-img>
          </v-col>
          <v-col>
            <span class="font-weight-bold black--text">Family Plan</span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialog" width="650" persistent>
      <!-- <template v-slot:activator="{ on, attrs }">
        
      </template> -->

      <v-card class="rounded-xl" elevation="15">
        <v-card-title class="text-h5 black--text font-weight-bold">
          Family Plan
          <v-spacer></v-spacer>
          <v-btn text icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-container grid-list-lg>
          <v-card-text>
            <v-layout row wrap align-center>
              <v-flex>
                Total Monthly Bill: <span class="font-weight-bold body-1">{{ monthlyBill | currency }}</span>
              </v-flex>
              <v-spacer></v-spacer>
              <v-flex shrink>
                <v-btn rounded outlined @click="addLine" v-if="currentLineCount !== 5">+ Add Another
                  Line</v-btn>
              </v-flex>
            </v-layout>

          </v-card-text>

          <v-layout row wrap justify-center>
            <v-flex v-for="(line, index) in Lines" :key="index">
              <v-card style="position: relative" v-if="line.show" outlined width="100" hover
                @click="openActivationSelectionDialog(line)" height="120" class="rounded-md">

                <v-btn @click="deleteLine" v-if="currentLineCount === line.id && !line.lock" color="teal" x-small dark
                  absolute top right fab>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <div style="height: 100%;" class="pa-1">
                  <v-layout column fill-height align-center justify-center>
                    <v-flex shrink class="caption text-center">
                      <div>{{ line.name }}</div>
                      <div>{{ line.amount | currency }}</div>
                    </v-flex>
                    <v-flex v-if="line.complete" shrink class="caption text-center ma-0 pa-0">
                      <v-icon color="green">mdi-check-decagram</v-icon>
                    </v-flex>
                  </v-layout>
                </div>


              </v-card>
              <!-- <v-btn color="primary" x-large v-if="line.show">{{ line.name }}
              </v-btn> -->
            </v-flex>

          </v-layout>

        </v-container>




        <v-divider></v-divider>

        <v-card-actions>

          <v-btn large :disabled="submitLine !== currentLineCount" block class="rounded-lg" :loading="loading"
            @click="submit" color="primary">Submit
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog ref="activationSelectionDialog" v-model="activationSelectionDialog" width="500" persistent>
      <v-card class="rounded-xl" elevation="15" color="grey lighten-2">
        <v-card-title class="text-h5">
          Activations
          <v-spacer></v-spacer>
          <v-btn text icon @click="activationSelectionDialog = !activationSelectionDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-container grid-list-lg>
          <v-layout row wrap justify-center>
            <v-flex :shrink="!$vuetify.breakpoint.xsOnly">
              <NewActivation ref="newNumber" inner="true" :selectedLine="selectedLine" @submit="newNumberSubmit"
                @closeSelection="activationSelectionDialog = !activationSelectionDialog" />
            </v-flex>
            <v-flex :shrink="!$vuetify.breakpoint.xsOnly">
              <PortIn ref="portInNumber" inner="true" :selectedLine="selectedLine" @submit="portInNumberSubmit"
                @closeSelection="activationSelectionDialog = !activationSelectionDialog" />
            </v-flex>
          </v-layout>

        </v-container>


      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import homeService from "@/modules/Home/service.js";
import { mapActions, mapGetters } from 'vuex'; import EventBus from '@/event-bus.js';
import NewActivation from "@/modules/Activation/components/NewNumber.vue"
import PortIn from "@/modules/Activation/components/PortIn.vue"
export default {
  name: 'family-plan',
  data() {
    return {
      selectedLine: null,
      activationSelectionDialog: false,
      currentLineCount: 2,
      Lines: [
        {
          id: 1,
          name: "Line 1 (Primary)",
          primary: true,
          amount: 50,
          show: true,
          lock: true,
          complete: false,
          activationType: 'parent'
        },
        {
          id: 2,
          name: "Line 2",
          primary: false,
          amount: 30,
          show: true,
          lock: true,
          complete: false,
          activationType: 'child'
        },
        {
          id: 3,
          name: "Line 3",
          primary: false,
          amount: 30,
          show: false,
          lock: false,
          complete: false,
          activationType: 'child'
        },
        {
          id: 4,
          name: "Line 4",
          primary: false,
          amount: 30,
          show: false,
          lock: false,
          complete: false,
          activationType: 'child'
        },
        {
          id: 5,
          name: "Line 5",
          primary: false,
          amount: 30,
          show: false,
          lock: false,
          complete: false,
          activationType: 'child'
        }
      ],
      errorMessage: null,
      dialog: false,
      simLoading: false,
      simFound: false,
      pinNumber: null,
      loading: false,
      simcard: null,
      loadingPlan: true,
      accountPin: null,
      selectedPlan: null,
      phoneTypeTab: 0,
      phoneType: 'iphone'
    }
  },
  components: {
    NewActivation,
    PortIn,
  },
  computed: {
    ...mapGetters("global", ["getContactNumber", "getContact"]),
    submitLine() {
      let lines = []
      this.Lines.map(line => {
        if (line.complete) {
          lines.push(line)
        }
      })
      return lines.length
    },
    monthlyBill() {
      let amount = 0
      for (var i = 0; i < this.currentLineCount; i++) {
        amount += parseFloat(this.Lines[i].amount)
      }
      return amount
    },
  },
  methods: {
    newNumberSubmit(data) {
      console.log('new number submitted', data)
      this.Lines.map(line => {
        console.log('tet', data)
        if (line.id === data.id) {
          line.complete = true
          line.metadata = data.data.metadata
          line.serialNumber = data.data.serialNumber
          line.status = 'activate'
          console.log('line to work on ', line)
        }
      })
      console.log('new number submitted after', this.Lines)
    },
    portInNumberSubmit(data) {
      console.log('portInNumberSubmit number submitted', data)
      this.Lines.map(line => {
        console.log('tet', data)
        if (line.id === data.id) {
          line.complete = true
          line.metadata = data.data.metadata
          line.serialNumber = data.data.serialNumber
          line.status = 'portIn'
          console.log('line to work on ', line)
        }
      })
      console.log('new number submitted after', this.Lines)
    },
    deleteLine() {
      this.Lines.map(line => {
        if (line.id === this.currentLineCount) {
          console.log('line number', line)
          line.show = false
        }
      })
      this.currentLineCount--
    },
    addLine() {
      if (this.currentLineCount !== 5) {
        this.currentLineCount++
        // for (var i = 0; i < this.Lines.length; i++) {
        //   console.log('array[i]', this.Lines[i])
        // }
        this.Lines.map(line => {
          // console.log('test line', line)
          if (line.id === this.currentLineCount) {
            // console.log('line number', line)
            line.show = true
          }
        })
      }
    },
    empty() {
      this.Lines = [
        {
          id: 1,
          name: "Line 1 (Primary)",
          simcard: null,
          amount: 50,
          show: true,
          lock: true,
        },
        {
          id: 2,
          name: "Line 2",
          simcard: null,
          amount: 30,
          show: true,
          lock: true,
        },
        {
          id: 3,
          name: "Line 3",
          simcard: null,
          amount: 30,
          show: false,
          lock: false,
        },
        {
          id: 4,
          name: "Line 4",
          simcard: null,
          amount: 30,
          show: false,
          lock: false,
        },
        {
          id: 5,
          name: "Line 5",
          simcard: null,
          amount: 30,
          show: false,
          lock: false,
        }
      ]
      this.currentLineCount = 2
      this.errorMessage = null
      this.loading = false
      this.simcard = null
    },
    close() {
      this.empty()
      this.dialog = false
    },
    openActivationSelectionDialog(line) {
      this.selectedLine = line
      console.log('refs', this.selectedLine)
      this.activationSelectionDialog = !this.activationSelectionDialog
      if (this.selectedLine && !this.selectedLine.primary) {
        setTimeout(() => {
          console.log('this.$refs.newNumber.dialog', this.$refs.newNumber.dialog)
          this.$refs.newNumber.dialog = true
        }, 100);
      }


    },
    submit() {

      if (this.currentLineCount !== this.submitLine) {
        this.$swal(
          "Order Not Complete",
          `Please complete ${this.currentLineCount - this.submitLine} missing line`,
          "error"
        );
        return false
      }

      let activeLines = []
      this.Lines.map(line => {
        if (line.complete) {
          activeLines.push(line)
        }
      })

      // if (this.planPick === 0) {

      this.markActive({
        familyPlan: true,
        lines: activeLines
      });
    },
    markActive(data) {
      // this.loading = true;
      return homeService
        .createFamilyPlan(data)
        .then((response) => {
          console.log("markActive response", response);
          this.activationSend = true;
          this.loading = false;
          this.dialog = false
          let contact = JSON.parse(localStorage.getItem(
            `${process.env.VUE_APP_STORAGE_PREFIX}contact`
          ))
          // homeService.emitCustomEvent({
          //   data: {
          //     ...data,
          //     Contact: contact,
          //     type: 'New Activation'
          //   },
          //   event: 'SIMACTIVATEDFROMPORTAL'
          // 
          this.$toast.open({
            message: 'Request submitted',
            dismissible: true,
            type: 'success',
            position: 'top-right',
          });
          // this.$swal(
          //   "Activation Created!",
          //   `Your Activation is created. Please wait for the action to be taken.`,
          //   "success"
          // );
          EventBus.$emit('reload-activation-list')
          this.empty()
        })
        .catch((error) => {
          console.log("error", error);

        });
    }
  }
}
</script>


