<template>
	<v-dialog v-model="dialog" width="500" persistent>


		<v-card class="rounded-xl" elevation="15" color="grey lighten-2">
			<v-card-title class="text-h5 font-weight-bold black--text">
				Reports
				<v-spacer></v-spacer>
				<v-btn text icon @click="close">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text>
				<v-row class="mt-5">
					<v-col>
						<v-card @click="billPaymentHistory" width="200" height="200" class="button-30 rounded-xl" :ripple="false"
							v-if="!$vuetify.breakpoint.xsOnly">
							<v-card-text style="height: 100%">
								<v-layout column fill-height align-center>
									<v-flex shrink>
										<v-img height="100" contain src="@/assets/bill.png"></v-img>
									</v-flex>
									<v-spacer></v-spacer>
									<v-flex class="text-h6 font-weight-bold black--text">
										Bill Payment
									</v-flex>
								</v-layout>
							</v-card-text>
						</v-card>
						<v-card v-else width="100%" @click="billPaymentHistory" :ripple="false" height="85"
							class="button-30 rounded-xl">
							<v-card-text style="height: 100%">
								<v-row align="center">
									<v-col class="shrink">
										<v-img width="50" contain src="@/assets/report.png"></v-img>
									</v-col>
									<v-col>
										<span class="font-weight-bold black--text">Bill Payment</span>
									</v-col>
								</v-row>

							</v-card-text>
						</v-card>
					</v-col>
					<!-- <v-col>
						<v-card @click="openOrderHistory" v-if="!$vuetify.breakpoint.xsOnly" width="200" height="200"
							class="button-30 rounded-xl" :ripple="false">
							<v-card-text style="height: 100%">
								<v-layout column fill-height align-center>
									<v-flex shrink>
										<v-img height="100" contain src="@/assets/transaction.png"></v-img>
									</v-flex>
									<v-spacer></v-spacer>
									<v-flex class="text-h6 font-weight-bold black--text">
										Transactions
									</v-flex>
								</v-layout>
							</v-card-text>
						</v-card>
						<v-card v-else width="100%" @click="openOrderHistory" :ripple="false" height="85"
							class="button-30 rounded-xl">
							<v-card-text style="height: 100%">
								<v-row align="center">
									<v-col class="shrink">
										<v-img width="50" contain src="@/assets/transaction.png"></v-img>
									</v-col>
									<v-col>
										<span class="font-weight-bold black--text">Transactions</span>
									</v-col>
								</v-row>
							</v-card-text>
						</v-card>
					</v-col> -->

					<v-col>
						<v-card @click="openSimHistory" v-if="!$vuetify.breakpoint.xsOnly"  width="200" height="200" class="button-30 rounded-xl" :ripple="false">
							<v-card-text style="height: 100%">
								<v-layout column fill-height align-center>
									<v-flex shrink>
										<v-img height="100" contain src="@/assets/sim.png"></v-img>
									</v-flex>
									<v-spacer></v-spacer>
									<v-flex class="text-h6 font-weight-bold black--text">
										Activations
									</v-flex>
								</v-layout>
							</v-card-text>
						</v-card>
						<v-card v-else width="100%" @click="openSimHistory" :ripple="false" height="85"
							class="button-30 rounded-xl">
							<v-card-text style="height: 100%">
								<v-row align="center">
									<v-col class="shrink">
										<v-img width="50" contain src="@/assets/sim.png"></v-img>
									</v-col>
									<v-col>
										<span class="font-weight-bold black--text">Activations</span>
									</v-col>
								</v-row>
							</v-card-text>
						</v-card>
					</v-col>

					<v-col>
						<v-card @click="openWalletHistory" v-if="!$vuetify.breakpoint.xsOnly" width="450" height="200" class="button-30 rounded-xl" :ripple="false">
							<v-card-text style="height: 100%">
								<v-layout column fill-height align-center>
									<v-flex shrink>
										<v-img height="100" contain src="@/assets/wallet.png"></v-img>
									</v-flex>
									<v-spacer></v-spacer>
									<v-flex class="text-h6 font-weight-bold black--text">
										Wallet
									</v-flex>
								</v-layout>
							</v-card-text>
						</v-card>
						<v-card v-else width="100%" @click="openWalletHistory" :ripple="false" height="85"
							class="button-30 rounded-xl">
							<v-card-text style="height: 100%">
								<v-row align="center">
									<v-col class="shrink">
										<v-img width="50" contain src="@/assets/sim1.png"></v-img>
									</v-col>
									<v-col>
										<span class="font-weight-bold black--text">Wallet</span>
									</v-col>
								</v-row>
							</v-card-text>
						</v-card>
					</v-col>

				</v-row>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>
<script>
import { mapActions } from 'vuex';
export default {
	name: 'History-Selecter-Dialog',
	props: ['modal'],
	data() {
		return {
			dialog: false
		}
	},
	methods: {
		...mapActions('global', ['setRandomNumber']),
		close() {

			this.$emit('close')
		},
		openOrderHistory() {

			this.$router.push({ path: "/History/orders" });
		},
		billPaymentHistory() {
			this.$router.push({ path: "/History/bills" });
		},
		openSimHistory() {

			this.$router.push({ path: "/History/sims" });
		},
		openWalletHistory() {

			this.$router.push({ path: "/History/wallet" });
		},

	},
	watch: {
		modal(val) {
			if (val) {
				return this.dialog = true
			}
			return this.dialog = false
		},
		dialog(val) {
			if (!val) {
				this.$emit('close')
			}
		}
	}
}
</script>
<style scoped>
.glassCard {
	/* From https://css.glass */
	background: rgba(225, 190, 231, 0.11) !important;
	border-radius: 16px;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(8.2px);
	-webkit-backdrop-filter: blur(8.2px);
	border: 1px solid rgba(225, 190, 231, 0.68) !important;
}
</style>