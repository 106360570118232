<template>
  <div>
    <v-card width="200" v-if="!$vuetify.breakpoint.xsOnly" @click="dialog = !dialog" :ripple="false" height="200"
      class="button-30 rounded-xl ">
      <v-card-text style="height: 100%">
        <v-layout column fill-height align-center>
          <v-flex shrink>
            <v-img height="100" contain src="@/assets/hotspot.png"></v-img>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex class="text-h6 black--text font-weight-bold">
            Hot Spot
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
    <v-card v-else width="100%" @click="dialog = !dialog" :ripple="false" height="85" class="button-30 rounded-xl">
      <v-card-text style="height: 100%">
        <v-row align="center">
          <v-col class="shrink">
            <v-img width="50" contain src="@/assets/hotspot.png"></v-img>
          </v-col>
          <v-col>
            <span class="font-weight-bold black--text">Hot Spot</span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>


    <v-dialog v-model="dialog" width="350" persistent>
      <!-- <template v-slot:activator="{ on, attrs }">
        
      </template> -->

      <v-card class="rounded-xl" elevation="15">
        <v-card-title class="text-h5 black--text font-weight-bold">
          Hot Spot
          <v-spacer></v-spacer>
          <v-btn text icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>

          <v-row class="mt-4">
            <v-col cols="12">
              <v-text-field dense outlined v-model="simcard" @click:clear="clearable()" clearable
                :error-messages="simCardErrors" :hide-details="simCardErrors.length === 0" autofocus label="Sim Number">
                <template v-slot:append>
                  <!-- <v-fade-transition leave-absolute> -->

                  <v-progress-circular v-if="simLoading" size="24" color="primary" indeterminate>
                  </v-progress-circular>
                  <div v-else>
                    <v-icon color="green" v-if="simFound === 'found'">mdi-check-circle</v-icon>
                    <v-icon color="red" v-if="simFound === 'notfound'">mdi-close-circle</v-icon>
                  </div>

                  <!-- </v-fade-transition> -->
                </template>

              </v-text-field>
              <div v-if="simFound === 'notfound'">{{ errorMessage }}</div>
            </v-col>
            <v-col cols="12">
              <v-text-field dense counter :hide-details="imeiErrors.length === 0" :error-messages="imeiErrors" outlined v-model="imeiNumber"
                @click:clear="clearable()" label="IMEI Number">
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>


            <v-col cols="12">
              <!-- <span>Select Plan <span class="red--text">*</span></span> -->
              <v-select :items="Plans" label="Select Plan" v-model="selectedPlan" dense :loading="loadingPlan"
                :error-messages="selectPlanErrors" :hide-details="selectPlanErrors.length === 0" outlined>
              </v-select>
            </v-col>
            <v-col cols="12">
              <!-- <span>Pin Number <span class="red--text">*</span></span> -->
              <v-text-field label="Pin Number" v-model="pinNumber" clearable dense outlined
                :error-messages="pinNumberErrors" :hide-details="pinNumberErrors.length === 0"></v-text-field>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col cols="6">
              <v-text-field label="Area Code (Optional)" v-model="areaCode" clearable dense outlined></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="Add On (Optional)" v-model="addon" clearable dense outlined></v-text-field>
            </v-col>
          </v-row> -->



        </v-card-text>



        <v-divider></v-divider>

        <v-card-actions>
          <!-- <v-spacer></v-spacer> -->
          <v-btn class="rounded-lg" block height="45" :disabled="simInformation ? false : true" :loading="loading"
            @click="submit" color="primary">Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import homeService from "@/modules/Home/service.js";
import { mapActions, mapGetters } from 'vuex';
const { validationMixin } = require("vuelidate");
const { required, minLength, requiredIf } = require("vuelidate/lib/validators");
export default {
  name: 'new-hotspot',
  data() {
    return {
      imeiNumber: null,
      simInformation: null,
      areaCode: null,
      addon: null,
      errorMessage: null,
      dialog: false,
      simLoading: false,
      simFound: false,
      pinNumber: null,
      loading: false,
      simcard: null,
      loadingPlan: true,
      accountPin: null,
      Plans: [],
      selectedPlan: null,
    }
  },
  computed: {
    ...mapGetters("global", ["getContactNumber", "getContact"]),
    imeiErrors() {
      const errors = [];
      if (this.$v.imeiNumber) {
        if (!this.$v.imeiNumber.$dirty) return errors;
        if (!this.$v.imeiNumber.required) errors.push("Imei number is required");
        if (!this.$v.imeiNumber.minLength)
          errors.push("IMEI number must be 15 digits.");
        if (!this.$v.imeiNumber.maxLength)
          errors.push("IMEI number must be 15 digits.");
      }

      return errors;
    },
    simCardErrors() {
      const errors = [];
      if (!this.$v.simcard.$dirty) return errors;
      if (!this.$v.simcard.required) errors.push("Sim card number is required");
      if (!this.$v.simcard.minLength)
        errors.push("Number must be atleast 14 digits");
      return errors;
    },
    pinNumberErrors() {
      const errors = [];
      if (!this.$v.pinNumber.$dirty) return errors;
      if (!this.$v.pinNumber.required) errors.push("Pin number is required");
      if (!this.$v.pinNumber.minLength)
        errors.push("Pin Number must be atleast 6 digits");
      return errors;
    },
    selectPlanErrors() {
      const errors = [];
      if (!this.$v.selectedPlan.$dirty) return errors;
      if (!this.$v.selectedPlan.required)
        errors.push("Select plan is required");
      return errors;
    },
  },
  watch: {
    simcard(val) {
      if (val && val.length > 19) {
        this.searchsim()
      }
    },
    dialog(val) {
      if (val) {
        this.getPlan();
      }
    }
  },
  mixins: [validationMixin],
  validations() {
    return {
      imeiNumber: {
        required,
      },
      simcard: {
        required,
        minLength: minLength(14),
      },
      selectedPlan: {
        required,
      },
      pinNumber: {
        required,
        minLength: minLength(6),
      },
    };
  },
  methods: {
    ...mapActions('global', ['setRandomNumber']),
    empty() {
      this.simInformation = null
      this.areaCode = null
      this.addon = null
      this.errorMessage = null
      this.simLoading = false
      this.simFound = false
      this.pinNumber = null
      this.loading = false
      this.simcard = null
      this.loadingPlan = true
      this.accountPin = null
      this.selectedPlan = null
      this.$v.$reset();
    },
    close() {
      this.empty()
      this.dialog = false
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$toast.open({
          message: 'Require fields missing',
          dismissible: true,
          type: 'error',
          position: 'top-right',
        });

        return false;
      }
      this.loading = true;

      const data = {
        serialNumber: this.simInformation.serialNumber,
        metadata: {
          simInfo: {
            imeiNumber: this.imeiNumber,
            createdAt: new Date(),
            plan: this.selectedPlan,
            pinNumber: this.pinNumber,
            activation: 'Hot Spot',
            Contact: this.getContact,
            areaCode: this.areaCode,
            addon: this.addon
          },
        },
      };

      // if (this.planPick === 1) {
      //   data.metadata.simInfo.activation = "Port In";
      //   data.metadata.simInfo.requestedPhoneNumber = this.requestedPhoneNumber;
      //   data.metadata.simInfo.accountNumber = this.accountNumber;
      //   data.metadata.simInfo.accountPin = this.accountPin;
      // }
      console.log("result", data);
      // if (this.planPick === 0) {

      this.markActive(data);
    },
    markActive(data) {
      return homeService
        .markActive(data)
        .then((response) => {
          // console.log("markActive response", response);
          this.activationSend = true;
          this.loading = false;
          this.dialog = false
          let contact = JSON.parse(localStorage.getItem(
            `${process.env.VUE_APP_STORAGE_PREFIX}contact`
          ))
          // homeService.emitCustomEvent({
          //   data: {
          //     ...data,
          //     Contact: contact,
          //     type: 'New Activation'
          //   },
          //   event: 'SIMACTIVATEDFROMPORTAL'
          // })
          this.$toast.open({
            message: 'Request submitted',
            dismissible: true,
            type: 'success',
            position: 'top-right',
          });
          // this.$swal(
          //   "Activation Created!",
          //   `Your Activation is created. Please wait for the action to be taken.`,
          //   "success"
          // );
          this.empty()
          this.$emit('submit')
        })
        .catch((error) => {
          console.log("error", error);

        });
    },
    getPlan() {
      this.loadingPlan = true;
      return homeService
        .getSettings()
        .then((response) => {
          this.loadingPlan = false;
          console.log('response plan', response)
          this.Plans = response.hotSpotPlan;
          this.setting = response.setting;
        })
        .catch((error) => {
          console.log("error", error);
          this.loadingPlan = false;
          // this.$swal(
          //   "Sim Status",
          //   "Invalid sim number please check sim number and try again.",
          //   "error"
          // );
        });
    },
    clearable() {
      console.log('clearable pressed')
      this.simcard = null
      this.errorMessage = null
    },
    searchsim() {
      this.simLoading = true;
      let newsim = this.simcard;
      const lastSimNumber = this.simcard.slice(-1);
      // console.log("lastSimNumber", lastSimNumber);
      if (lastSimNumber === "f" || lastSimNumber === "F") {
      } else {
        newsim += "F";
      }
      return homeService
        .getSimInfo(newsim)
        .then((response) => {
          console.log("response.data.status", response);
          if (
            response.data.status === "activated" ||
            response.data.status === "activate"
          ) {
            this.errorMessage =
              "This sim is already activated. Check the sim number and try again";
            this.simLoading = false;
            this.simFound = "notfound";
            return false;
          }
          this.simLoading = false;
          this.simFound = "found";
          this.simInformation = response.data;
        })
        .catch((error) => {
          this.simLoading = false;
          this.simFound = "notfound";
          this.errorMessage =
            "Invalid sim number please check sim number and try again.";
          // this.$swal(
          //   "Sim Status",
          //   "Invalid sim number please check sim number and try again.",
          //   "error"
          // );
        });
    },
    // keyup() {
    //   clearTimeout(this.typingTimer);
    //   this.typingTimer = setTimeout(() => {
    //     this.searchsim();
    //   }, this.doneTypingInterval);
    // },
    // keydown() {
    //   clearTimeout(this.typingTimer);
    // },
  }
}
</script>


