<template>
	<v-dialog v-model="dialog" width="500">
		<v-card class="rounded-xl" elevation="15" color="grey lighten-2">
			<v-card-title class="text-h5 font-weight-bold black--text">
				Support
				<v-spacer></v-spacer>
				<v-btn text icon @click="close">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-container grid-list-lg class="pb-8">
				<v-layout row wrap justify-center>
					<v-flex :shrink="!$vuetify.breakpoint.xsOnly">
							<SimSwip />				
					</v-flex>
					<v-flex :shrink="!$vuetify.breakpoint.xsOnly">
						<ChangePlan />
					</v-flex>
				</v-layout>
			</v-container>
		</v-card>
	</v-dialog>
</template>
<script>
import { mapActions } from 'vuex';
import ChangePlan from "@/modules/Tasks/components/ChangePlan.vue"
import SimSwip from "@/modules/Tasks/components/SimSwip.vue"

export default {
	name: 'support-Selecter-Dialog',
	props: ['modal'],
	data() {
		return {
			dialog: false
		}
	},
	components: {
		SimSwip,
		ChangePlan,
	},
	methods: {
		...mapActions('global', ['setRandomNumber']),
		close() {

			this.$emit('close')
		},
		openOrderHistory() {

			this.$router.push({ path: "/History/orders" });
		},
		openSimHistory() {

			this.$router.push({ path: "/History/sims" });
		}
	},
	watch: {
		modal(val) {
			if (val) {
				return this.dialog = true
			}
			return this.dialog = false
		},
		dialog(val) {
			if (!val) {
				this.$emit('close')
			}
		}
	}
}
</script>
<style scoped>
.glassCard {
	/* From https://css.glass */
	background: rgba(225, 190, 231, 0.11) !important;
	border-radius: 16px;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(8.2px);
	-webkit-backdrop-filter: blur(8.2px);
	border: 1px solid rgba(225, 190, 231, 0.68) !important;
}
</style>