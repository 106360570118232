const Index = () => import('../index.vue');
const SimHistory = () => import('@/modules/History/components/SimHistory.vue');
const OrderHistory = () => import('@/modules/History/components/OrderHistory.vue');
const BillPaymentHistory = () => import('@/modules/History/components/BillPaymentHistory.vue');
const WalletHistory = () => import('@/modules/History/components/WalletHistory.vue');

export default [
  {
    path: '/History',
    component: Index,
    title: 'History',
    children: [
      {
        path: 'sims',
        name: 'sim-history',
        component: SimHistory,
        props: true,
      },
      {
        path: 'orders',
        name: 'order-history',
        component: OrderHistory,
      },
      {
        path: 'bills',
        name: 'bill-history',
        component: BillPaymentHistory,
      },
      {
        path: 'wallet',
        name: 'wallet-history',
        component: WalletHistory,
      },
    ],
  },
];
