<template>
  <v-data-table v-if="support.length !== 0" class="mt-3 table-header" hide-default-footer :headers="headers" :items="support"
    :loading="loading" :items-per-page="support.length">
    <template v-slot:item="{ item }">
      <tr class="pointer">
        <td>{{ item.createdAt | moment('MM/DD/YYYY LT') }} - {{ item.Contact.businessName }}</td>
        <td>{{ item.title }} </td>
        <td>{{ item.phoneNumber }}</td>
        <td class="text-capitalize">{{ item.type }}</td>
        <!-- <td>{{ item.Carrier.name }}</td> -->
        <td>
          <v-chip class="text-capitalize" small label dark :color="color(item.status)">
            {{ item.status }}
          </v-chip>
        </td>
      </tr>
    </template>
  </v-data-table>

  <!-- <div v-else class="text-h5 font-weight-black text-center v-window__container center">
    You have no support ticket
  </div> -->
</template>

<script>
import taskService from "@/modules/Tasks/service.js";
import EventBus from '@/event-bus.js';

export default {
  name: 'task-list',
  data() {
    return {
      loading: false,
      support: [],
      limit: 20,
      orderBy: ['id', 'true'],
      headers: [
        {
          text: 'Date',
          align: "left",
          value: "createdAt",
          sortable: true,
        },
        {
          text: "Title",
          value: "title",
          sortable: true,
        },
        {
          text: "Phone Number",
          value: "phoneNumber",
          sortable: true,
        },
        {
          text: "Type",
          value: "type",
          sortable: true,
        },
        {
          text: "Status",
          value: "status",
          sortable: false,
        },
      ],
      contact: JSON.parse(localStorage.getItem(
        `${process.env.VUE_APP_STORAGE_PREFIX}contact`
      ))
    }
  },
  created() {
    this.getTaskList()
    EventBus.$on('reload-task-list', this.getTaskList)
  },
  beforeDestroy() {
    EventBus.$off('reload-task-list')
  },
  methods: {
    color(val) {
			let value = null
			switch (val) {
				case 'paid':
					value = 'green'
					break;
				case 'pending':
					value = 'yellow accent-4'
					break;
				case 'open':
					value = 'grey darken-2'
					break;
				case 'closed':
					value = 'green'
					break;
				case 'rejected':
					value = 'red accent-4'
					break;
				// default:
				// value = 'grey'
				// 	break;
			}
			// console.log('value', value, val)
			return value
		},
    async getTaskList() {
      try {
        this.loading = true
        let response = await taskService.getAll({
          limit: this.limit,
          orderBy: this.orderBy,
          contactId: this.contact.id,
        })
        console.log('task list', response);
        this.support = response.data
        // this.sims = []
        // console.log('response.data is :', response.data)
        this.loading = false
      } catch (error) {
        this.loading = false
        console.log('erir is :', error)
        // this.$swal('error occured');
      }
    },
  }
}
</script>

<style scoped>

</style>