import cellsimPortalService from '@/api/cellSim.js';

export default {
  /**
   * Get list of products
   * @param {*} params
   * @param {*} params.limit
   * @param {*} params.offset
   * @param {*} params.search
   * @param {*} params.local Add LocalData to products
   */
   create(data) {
    return cellsimPortalService.Api().post('task', data)
      .then(response => response.data)
      .catch((error) => { throw error })
  },

  getAll(params) {
    return cellsimPortalService.Api().get('task', {params})
      .then(response => response.data)
      .catch((error) => { throw error })
  },

  getById(id, params) {
    return cellsimPortalService.Api().get(`task/${id}`, {params})
      .then(response => response.data)
      .catch((error) => { throw error })
  },

  update(id, data) {
    return cellsimPortalService.Api().put(`task/${id}`, data)
      .then(response => response.data)
      .catch((error) => { throw error })
  },

  delete(id) {
    return cellsimPortalService.Api().delete(`task/${id}`)
      .then(response => response.data)
      .catch((error) => { throw error })
  },

  searchPhoneNumber(params) {
    return cellsimPortalService.Api().get('activation', {params})
      .then(response => response.data)
      .catch((error) => { throw error })
  },

  

}
