<template>
  <div>
    <v-data-table v-if="billPayment.length !== 0" class="mt-3 table-header" hide-default-footer :headers="headers"
      :items="billPayment" :loading="loading" :items-per-page="billPayment.length">
      <template v-slot:item="{ item }">
        <tr class="pointer" @click="openReceiptDialog(item)">
          <td>{{ item.createdAt | moment('MM/DD/YYYY LT') }}</td>
          <td>{{ item.phoneNumber }}</td>
          <td>{{ item.Carrier.name }}</td>
          <td>{{ item.amount | currency }}</td>
          <td>
            <v-chip class="text-capitalize" small label dark :color="color(item.status)">
              {{ item.status }}
            </v-chip>
          </td>
        </tr>
      </template>
    </v-data-table>

    <!-- <div v-else class="text-h5 font-weight-black text-center v-window__container center">
      You have activate any sim yet!
    </div> -->
    <Receipt :receipt="selectedItem" :openReceipt="openReceipt" @close="(openReceipt = false)"/>
  </div>
</template>

<script>
import BillPaymentService from "@/modules/BillPayment/service.js";
import EventBus from '@/event-bus.js';
import Receipt from '@/modules/BillPayment/components/Receipt.vue'

export default {
  name: 'billpayment-list',
  data() {
    return {
      selectedItem: null,
      openReceipt: false,
      loading: false,
      billPayment: [],
      limit: 10,
      orderBy: ['createdAt', 'true'],
      headers: [
        {
          text: 'Date',
          align: "left",
          value: "createdAt",
          sortable: true,
        },
        {
          text: "Phone Number",
          value: "phoneNumber",
          sortable: true,
        },
        {
          text: 'Carrier',
          value: "Carrier.name",
          sortable: true,
        },
        {
          text: 'Amount',
          value: "amount",
          sortable: true,
        },
        {
          text: "Status",
          value: "status",
          sortable: false,
        },
      ],
      contact: JSON.parse(localStorage.getItem(
        `${process.env.VUE_APP_STORAGE_PREFIX}contact`
      ))
    }
  },
  components: {
    Receipt,
  },
  created() {
    this.getBillPayment()
    EventBus.$on('reload-billPayment-list', this.getBillPayment)
  },
  beforeDestroy() {
    EventBus.$off('reload-billPayment-list')
  },
  methods: {
    openReceiptDialog(val) {
      this.selectedItem = val
      this.openReceipt = !this.openReceipt
    },
    color(val) {
      let value = null
      switch (val) {
        case 'paid':
          value = 'green'
          break;
        case 'pending':
          value = 'grey darken-2'
          break;
        case 'open':
          value = 'grey darken-2'
          break;
        case 'closed':
          value = 'green'
          break;
        case 'rejected':
          value = 'red accent-4'
          break;
        // default:
        // value = 'grey'
        // 	break;
      }
      // console.log('value', value, val)
      return value
    },
    async getBillPayment() {
      try {
        this.loading = true
        let response = await BillPaymentService.getAll({
          limit: this.limit,
          orderBy: this.orderBy,
          contactId: this.contact.id,
        })
        console.log('billPayment list', response);
        this.billPayment = response.data
        // this.sims = []
        // console.log('response.data is :', response.data)
        this.loading = false
      } catch (error) {
        this.loading = false
        console.log('erir is :', error)
        // this.$swal('error occured');
      }
    },
  }
}
</script>

<style scoped>

</style>