import cellsimPortalService from '@/api/cellSim.js';

export default {
  /**
   * Get list of products
   * @param {*} params
   * @param {*} params.limit
   * @param {*} params.offset
   * @param {*} params.search
   * @param {*} params.local Add LocalData to products
   */
  create(data) {
    return cellsimPortalService.Api().post('order', data)
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },

  getAll(params) {
    return cellsimPortalService.Api().get('order', {
        params
      })
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },

  getById(id, params) {
    return cellsimPortalService.Api().get(`order/${id}`, {
        params
      })
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },

  update(id, data) {
    return cellsimPortalService.Api().put(`order/${id}`, data)
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },

  delete(id) {
    return cellsimPortalService.Api().delete(`order/${id}`)
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },


}