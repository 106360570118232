<template>
  <div>
    <v-card width="200" @click="dialog = !dialog" :ripple="false" height="200" class="button-30 rounded-xl"
      v-if="!$vuetify.breakpoint.xsOnly">
      <v-card-text style="height: 100%">
        <v-layout column fill-height align-center>
          <v-flex shrink>
            <v-img height="100" contain src="@/assets/bill.png"></v-img>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex class="text-h6 font-weight-bold black--text">
            Bill Payment
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
    <v-card v-else width="100%" @click="dialog = !dialog" :ripple="false" height="85" class="button-30 rounded-xl">
      <v-card-text style="height: 100%">
        <v-layout row align-center>
          <v-flex shrink>
            <v-img width="50" contain src="@/assets/bill.png"></v-img>
          </v-flex>
          <v-flex>
            <span class="font-weight-bold black--text">Bill Payment</span>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialog" width="500" persistent>
      <!-- <template v-slot:activator="{ on, attrs }">
        
      </template> -->

      <v-card class="rounded-xl" elevation="15">
        <v-card-title class="text-h5 black--text font-weight-bold">
          Bill Payment
          <v-spacer></v-spacer>
          <v-btn text icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>


        <v-card-text>
          <div class="teal--text text--accent-2">Balance: <span class="font-weight-bold">{{ getBalance | currency
          }}</span></div>
          <v-row class="mt-4">
            <v-col cols="12">
              <v-select hide-details item-text="name" :loading="carrierLoading" dense :items="Carriers"
                v-model="selectedCarrier" outlined :close-on-content-click="true" return-object>
                <template v-slot:item="{ item }">
                  <v-list-item ripple>
                    <v-list-item-avatar tile>
                      <v-img tile v-if="item.imageUrl" :src="item.imageUrl"></v-img>
                      <v-img v-else src="@/assets/imageMissing.png"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <!-- <template #selection="{ item }">
                  <v-list-item >
                    <v-list-item-avatar tile>
                      <v-img tile v-if="item.imageUrl" :src="item.imageUrl"></v-img>
                      <v-img v-else src="@/assets/imageMissing.png"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template> -->
              </v-select>
            </v-col>
            <v-col cols="6">
              <v-text-field @keyup.enter="$refs.confirNumber.focus" dense hide-details outlined v-model="phoneNumber"
                :error-messages="phoneNumberErrors" :hide-details="phoneNumberErrors.length === 0"
                @focus="$event.target.select()" autofocus label="Phone Number">
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field @keyup.enter="$refs.amount.focus" ref="confirNumber" dense hide-details outlined
                v-model="confirmPhoneNumber" :error-messages="confirmPhoneNumberErrors"
                :hide-details="confirmPhoneNumberErrors.length === 0" @focus="$event.target.select()"
                label="Confirm Phone Number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="text-center" v-if="total !== 0">
                <div class="text-h5 font-weight-bold black--text">Total</div>
                <div class="text-h3 font-weight-bold black--tex">{{ total | currency }}</div>
              </div>
            </v-col>
            <v-col>
              <v-row>
                <v-col cols="12">
                  <v-text-field @keyup.enter="$refs.fee.focus" ref="amount" reverse hide-details dense outlined
                    v-model="amount" :error-messages="amountErrors" :hide-details="amountErrors.length === 0"
                    @focus="$event.target.select()" label="Amount">
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field ref="fee" @keyup.enter="submit" reverse :error-messages="feeErrors"
                    :hide-details="feeErrors.length === 0" dense outlined v-model="fee" @focus="$event.target.select()"
                    label="Fee">
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>

          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <!-- <v-spacer></v-spacer> -->
          <v-btn block height="45" class="rounded-lg" ref="submitBtn" :loading="loading" @click="submit" color="primary">
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Receipt :receipt="selectedBillPayment" :openReceipt="openReceipt" @close="closeReceipt()" />
  </div>
</template>

<script>
import billPaymentService from "@/modules/BillPayment/service.js";
import EventBus from '@/event-bus.js';
import { mapGetters } from 'vuex';
import Receipt from '@/modules/BillPayment/components/Receipt.vue'
const { validationMixin } = require("vuelidate");
const { required, minLength, sameAs, minValue, maxValue } = require("vuelidate/lib/validators");

export default {
  name: 'billPayment',
  components: {
    Receipt
  },
  data() {
    return {
      test: {},
      openReceipt: false,
      carrierLoading: true,
      phoneNumber: null,
      selectedBillPayment: null,
      confirmPhoneNumber: null,
      simInformation: null,
      selectedCarrier: null,
      amount: 0,
      fee: 0,
      dialog: false,
      loading: false,
      description: null,
      Carriers: [],
      limit: 1,
      orderBy: ['id', 'true'],
    }
  },
  computed: {
    ...mapGetters('global', ['getBalance', 'getContact']),
    // phoneNumberCheck() {
    //   if (this.phoneNumber && this.phoneNumber === this.confirmPhoneNumber) {
    //     return false
    //   }
    //   return true
    // },
    total() {
      return parseFloat(this.amount) + parseFloat(this.fee)
    },
    phoneNumberErrors() {
      const errors = [];
      if (!this.$v.phoneNumber.$dirty) return errors;
      if (!this.$v.phoneNumber.required) errors.push("Phone number is required");
      if (!this.$v.phoneNumber.minLength)
        errors.push("Number must be atleast 10 digits");
      return errors;
    },
    amountErrors() {
      const errors = [];
      if (!this.$v.amount.$dirty) return errors;
      if (!this.$v.amount.required) errors.push("Amount is required");
      if (!this.$v.amount.minValue)
        errors.push("Payment should be more then $10.00");
      if (!this.$v.amount.maxValue)
        errors.push("Payment should be greater then $100.00");
      return errors;
    },
    feeErrors() {
      const errors = [];
      if (!this.$v.fee.$dirty) return errors;
      if (!this.$v.fee.required) errors.push("Fee amount is required");
      // if (!this.$v.amount.minValue)
      //   errors.push("Payment should be more then $10.00");
      return errors;
    },
    confirmPhoneNumberErrors() {
      const errors = [];
      // console.log('phoneNumber', this.$v.confirmPhoneNumber.sameAsPhoneNumber)
      if (!this.$v.confirmPhoneNumber.$dirty) return errors;
      if (!this.$v.confirmPhoneNumber.required) errors.push("Phone number is required");
      if (!this.$v.confirmPhoneNumber.sameAsPhoneNumber) errors.push("Phone number is not matching");
      // if (!this.$v.confirmPhoneNumber.minLength)
      //   errors.push("Phone Number must be atleast 10 digits");
      return errors;
    },
    // selectPlanErrors() {
    //   const errors = [];
    //   if (!this.$v.selectedPlan.$dirty) return errors;
    //   if (!this.$v.selectedPlan.required)
    //     errors.push("Select plan is required");
    //   return errors;
    // },
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.empty()
      } else {
        billPaymentService.getCarrier()
          .then(result => {
            console.log('result', result)
            this.Carriers = result.data
            this.selectedCarrier = this.Carriers[0]
            this.carrierLoading = false
          })
      }
      if (
        this.getContact &&
        this.getContact.metadata &&
        this.getContact.metadata.defaultFee
      ) this.fee = this.getContact.metadata.defaultFee;
    }
  },
  created() {
    if (
      this.getContact &&
      this.getContact.metadata &&
      this.getContact.metadata.defaultFee
    ) this.fee = this.getContact.metadata.defaultFee;
  },
  mixins: [validationMixin],
  validations() {
    return {
      phoneNumber: {
        required,
        minLength: minLength(10),
      },
      confirmPhoneNumber: {
        required,
        sameAsPhoneNumber: sameAs(function () { return this.phoneNumber }),
      },
      amount: {
        required,
        minValue: minValue(10),
        maxValue: maxValue(100),
      },
      fee: {
        required,
      },
    };
  },
  methods: {
    searchPhoneNumber() {
      // console.log('searchPhoneNumber', this.phoneNumber)
      if (this.phoneNumber) {
        return billPaymentService.getAll(
          {
            phoneNumber: this.phoneNumber,
            limit: this.limit,
            orderBy: this.orderBy,
          })
          .then((response) => {
            console.log("BillPayment response", response);
            if (response.data.length) {
              this.amount = response.data[0].amount
              this.fee = response.data[0].fee
            }

          })
          .catch((error) => {
            console.log("error", error);
          });
      }

    },
    empty() {
      this.phoneNumber = null
      this.confirmPhoneNumber = null
      this.amount = 0
      this.fee = 0
      this.$v.$reset();
    },
    closeReceipt() {
      this.openReceipt = false
      this.selectedBillPayment = null
    },
    async openBillPaymentReceipt(id) {
      return billPaymentService.getById(id)
        .then(response => {
          this.selectedBillPayment = response.data
          this.openReceipt = true
          return response.data
        })
        .catch(error => {
          throw error
        })
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$toast.open({
          message: 'Require fields missing',
          dismissible: true,
          type: 'error',
          position: 'top-right',
        });
        return false;
      }


      // if (parseFloat(this.getBalance) < parseFloat(this.amount)) {
      //   this.$swal({
      //     title: 'Insufficient Funds',
      //     text: `Your current balance is: ${this.$options.filters.currency(this.getBalance)}.`,
      //     icon: "error",
      //   })
      //   return false
      // }

      this.loading = true;

      const data = {
        CarrierId: this.selectedCarrier.id,
        phoneNumber: this.phoneNumber,
        amount: this.amount,
        fee: this.fee,
      };


      return billPaymentService.create(data)
        .then((response) => {
          console.log('response.data.id', response.data.id)
          this.openBillPaymentReceipt(response.data.id)
          // console.log("BillPayment response", response);
          this.loading = false;
          this.dialog = false
          EventBus.$emit('reload-billPayment-list')
          this.$toast.open({
            message: 'Request submitted',
            dismissible: true,
            type: 'success',
            position: 'top-right',
          });
          this.empty()
          // this.$swal(
          //   "Activation Created!",
          //   `Your Activation is created. Please wait for the action to be taken.`,
          //   "success"
          // );
        })
        .catch((error) => {
          this.loading = false;
          this.$toast.open({
            message: error.data.message,
            dismissible: true,
            type: 'error',
            position: 'top-right',
          });
          console.log("error", error);
        });

    },
  }
}
</script>

<style scoped>
/* CSS */
</style>