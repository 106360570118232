<template>
  <div>
    <v-card width="200" v-if="!$vuetify.breakpoint.xsOnly" @click="dialog = !dialog" height="200" class="button-30 rounded-xl" :ripple="false">
      <v-card-text style="height: 100%">
        <v-layout column fill-height align-center>
          <v-flex shrink>
            <v-img height="100" contain src="@/assets/clipboard.png"></v-img>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex class="text-h6 font-weight-bold black--text">
            Plan Change
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
    <v-card v-else width="100%" @click="dialog = !dialog" :ripple="false" height="85" class="button-30 rounded-xl">
      <v-card-text style="height: 100%">
        <v-row align="center">
          <v-col class="shrink">
            <v-img width="50" contain src="@/assets/clipboard.png"></v-img>
          </v-col>
          <v-col>
            <span class="font-weight-bold black--text">Plan Change</span>
          </v-col>
        </v-row>
        
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialog" width="500" persistent>
      <!-- <template v-slot:activator="{ on, attrs }">
        
      </template> -->

      <v-card class="rounded-xl" elevation="15">
        <v-card-title class="text-h5 font-weight-bold black--text">
          Plan Change
          <v-spacer></v-spacer>
          <v-btn text icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-4">
            <v-col cols="6">
              <v-text-field dense autofocus @keyup.enter="$refs.pinNumber.focus" outlined v-model="phoneNumber"
                @click:clear="clearPhoneNumber()" clearable 
                :error-messages="phoneNumberErrors" :hide-details="phoneNumberErrors.length === 0" label="Phone Number">
                <template v-slot:append>
                  <!-- <v-fade-transition leave-absolute> -->

                  <v-progress-circular v-if="phoneLoading" size="24" color="primary" indeterminate>
                  </v-progress-circular>
                  <div v-else>
                    <v-icon color="green" v-if="phoneFound === 'found'">mdi-check-circle</v-icon>
                    <v-icon color="red" v-if="phoneFound === 'notfound'">mdi-close-circle</v-icon>
                  </div>

                  <!-- </v-fade-transition> -->
                </template>

              </v-text-field>
              <div v-if="phoneFound === 'notfound'">{{ phoneErrorMessage }}</div>
            </v-col>
            <v-col cols="6">
              <v-text-field label="Pin Number" @keyup.enter="$refs.plan.focus" ref="pinNumber" v-model="pinNumber"
                clearable dense outlined :error-messages="pinNumberErrors" :hide-details="pinNumberErrors.length === 0">
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>




            <v-col cols="12">
              <v-select :items="Plans" ref="plan" label="New Plan" v-model="selectedPlan" dense :loading="loadingPlan"
                :error-messages="selectPlanErrors" :hide-details="selectPlanErrors.length === 0" outlined></v-select>
            </v-col>

            <div class="teal--text text--accent-2 font-weight-bold text-center">* New plan payment must be made before submitting request.</div>
            <!-- <v-col cols="6">
              <v-text-field label="Add On (Optional)" v-model="addon" clearable dense outlined>
              </v-text-field>
            </v-col> -->



          </v-row>


        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <!-- <v-spacer></v-spacer> -->
          <v-btn height="45" block :loading="loading" @click="submit" color="primary">Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>
import homeService from "@/modules/Home/service.js";
import taskService from "@/modules/Tasks/service.js";
import EventBus from '@/event-bus.js';
import { mapActions, mapGetters } from 'vuex';
const { validationMixin } = require("vuelidate");
const { required, minLength, requiredIf } = require("vuelidate/lib/validators");
export default {
  name: 'plan-change',
  data() {
    return {
      phoneNumber: null,
      phoneErrorMessage: null,
      phoneInformation: null,
      dialog: false,
      phoneFound: null,
      pinNumber: null,
      loading: false,
      doneTypingInterval: 300,
      loadingPlan: true,
      phoneLoading: false,
      accountPin: null,
      Plans: [],
      selectedPlan: null,
    }
  },
  computed: {
    ...mapGetters("global", ["getContactNumber", "getContact"]),
    pinNumberErrors() {
      const errors = [];
      if (!this.$v.pinNumber.$dirty) return errors;
      if (!this.$v.pinNumber.required) errors.push("Pin number is required");
      if (!this.$v.pinNumber.minLength)
        errors.push("Pin Number must be atleast 6 digits");
      return errors;
    },
    selectPlanErrors() {
      const errors = [];
      if (!this.$v.selectedPlan.$dirty) return errors;
      if (!this.$v.selectedPlan.required)
        errors.push("Select plan is required");
      return errors;
    },
    phoneNumberErrors() {
      const errors = [];
      if (!this.$v.phoneNumber.$dirty) return errors;
      if (!this.$v.phoneNumber.required) errors.push("Phone Number number is required");
      if (!this.$v.phoneNumber.minLength)
        errors.push("Number must be atleast 10 digits");
      return errors;
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.getPlan();
      }
    },
    phoneNumber(val) {
      if (val && val.length === 10) {
        this.searchPhoneNumber()
      }
    }
  },
  mixins: [validationMixin],
  validations() {
    return {
      selectedPlan: {
        required,
      },
      pinNumber: {
        required,
        minLength: minLength(6),
      },
      phoneNumber: {
        required,
        minLength: minLength(10),
      },
    };
  },
  methods: {
    ...mapActions('global', ['setRandomNumber']),
    searchPhoneNumber() {
      this.phoneLoading = true;
      console.log('test')
      return taskService
        .searchPhoneNumber({ phoneNumber: this.phoneNumber })
        .then((response) => {
          console.log("response.data.status", response);
          if (response.data.length === 0) {
            console.log('not found')
            this.phoneErrorMessage =
              "Phone number not found.";
            this.phoneLoading = false;
            this.phoneFound = "notfound";
            return false;
          }
          this.phoneLoading = false;
          this.phoneFound = "found";
          this.phoneInformation = response.data;
        })
        .catch((error) => {
          this.phoneLoading = false;
          this.phoneFound = "notfound";
          this.phoneErrorMessage =
            "Phone number not found.";
          // this.$swal(
          //   "Sim Status",
          //   "Invalid sim number please check sim number and try again.",
          //   "error"
          // );
        });
    },
    close() {
      this.empty()
      this.dialog = false
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$toast.open({
          message: 'Require fields missing',
          dismissible: true,
          type: 'error',
          position: 'top-right',
        });
        return false;
      }
      if (this.phoneFound === "notfound") {
        this.$toast.open({
          message: 'Phone number not found',
          dismissible: true,
          type: 'error',
          position: 'top-right',
        });
        return false
      }
      this.loading = true;

      const data = {
        phoneNumber: this.phoneNumber,
        title: 'Plan Change Request',
        metadata: {
          pinNumber: this.pinNumber,
          plan: this.selectedPlan
        },
        type: 'Plan Change',
        status: 'open'
      };


      console.log('data', data)

      return taskService.create(data)
        .then(result => {
          console.log('reuslt', result)
          this.$toast.open({
            message: 'Request submitted',
            dismissible: true,
            type: 'success',
            position: 'top-right',
          });
          this.loading = false
          EventBus.$emit('reload-task-list')
          this.dialog = false
          this.empty()
        }).catch(err => {
          this.loading = false
        })


    },
    empty() {
      this.phoneNumber = null
      this.phoneErrorMessage= null
      this.phoneInformation= null
      this.addon = null
      this.simLoading = false
      this.simFound = false
      this.pinNumber = null
      this.loading = false
      this.loadingPlan = true
      this.selectedPlan = null
      this.$v.$reset();
    },
    getPlan() {
      this.loadingPlan = true;
      return homeService
        .getSettings()
        .then((response) => {
          this.loadingPlan = false;
          this.Plans = response.plan;
          this.setting = response.setting;
        })
        .catch((error) => {
          console.log("error", error);
          this.loadingPlan = false;
          // this.$swal(
          //   "Sim Status",
          //   "Invalid sim number please check sim number and try again.",
          //   "error"
          // );
        });
    },
    clearPhoneNumber() {
      this.phoneNumber = null
      this.phoneErrorMessage = null
    },
    // keyup() {
    //   clearTimeout(this.typingTimer);
    //   this.typingTimer = setTimeout(() => {
    //     this.searchPhoneNumber();
    //   }, this.doneTypingInterval);
    // },
    // keydown() {
    //   clearTimeout(this.typingTimer);
    // },
  }
}
</script>

<style scoped>
/* CSS */
</style>