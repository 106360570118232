<template>
	<div>
		<v-row align="center">
			<v-col cols="9">
				<h1 v-if="!$vuetify.breakpoint.xsOnly" class="black--text font-weight-bold">Recent Transactions</h1>
				<div v-else class="black--text font-weight-bold">Recent Transactions</div>
			</v-col>

			<v-spacer></v-spacer>
			<v-col class="text-right">
				<v-btn :loading="loading" color="teal accent-4" icon @click="reloadtab">
					<v-icon>mdi-reload</v-icon>
				</v-btn>
			</v-col>
		</v-row>

		<ActivationList />

		<!-- <v-tabs right v-model="tab">
			<v-tab>Activation</v-tab>
			<v-tab>Bill Payment</v-tab>
			<v-tab>Support</v-tab>
		</v-tabs>

		<v-tabs-items v-model="tab" class="touch-view-container">
			<v-tab-item>

				<ActivationList />
			</v-tab-item>
			<v-tab-item>
				<BillPaymentList />
			</v-tab-item>
			<v-tab-item>
				<TaskList />
			</v-tab-item>
		</v-tabs-items> -->

	</div>
</template>
<script>
// import homeService from "@/modules/Home/service.js";
import EventBus from '@/event-bus.js';
import BillPaymentList from '@/modules/BillPayment/components/List.vue'
import TaskList from '@/modules/Tasks/components/List.vue'
// import homeService from '@/modules/Home/service.js'
import ActivationList from '@/modules/Activation/components/List.vue'

export default {
	name: 'recent-transaction-list',
	data() {
		return {
			tab: 0,
			loading: false,
			contact: JSON.parse(localStorage.getItem(
				`${process.env.VUE_APP_STORAGE_PREFIX}contact`
			))
		}
	},
	components: {
		TaskList,
		ActivationList,
		BillPaymentList
	},
	beforeDestroy() {
		EventBus.$off('reload-activation-list')
		EventBus.$off('reload-billPayment-list')
		EventBus.$off('reload-activation-list')
	},
	sockets: {
		SERIALCREATED(data) {
			console.log('SERIALCREATED data', data)
			// this.loadData()
			this.$toast.open({
				message: 'Status Update',
				dismissible: true,
				type: 'default',
				position: 'top-right',
			});
		},
		SERIALUPDATED(data) {
			console.log('SERIALUPDATE data', data)
			// this.loadData()
			EventBus.$emit('reload-activation-list')
			this.loadContactData()
			this.$toast.open({
				message: 'Status Update',
				dismissible: true,
				type: 'default',
				position: 'top-right',
			});
		},
		CREATEDNEWBILLPAYMENT(data) {
			console.log('test sokect got call')
			// this.getBillPayment()
			EventBus.$emit('reload-billPayment-list')
			this.$toast.open({
				message: 'Status Update',
				dismissible: true,
				type: 'default',
				position: 'top-right',
			});
		},
		TASKUPDATED() {
			console.log('TASKUPDATED data')
			EventBus.$emit('reload-task-list')
			this.$toast.open({
				message: 'Status Update',
				dismissible: true,
				type: 'default',
				position: 'top-right',
			});

		},
		SERIALDELETED(data) {
			console.log('SERIALDELETED data', data)

			EventBus.$off('reload-activation-list')
		}

	},
	methods: {
		// ...mapActions("global", ['setContact']),
		// loadContactData() {
		// 	homeService.Customer.getById(this.getContact.id)
		// 		.then(result => {
		// 			console.log('getContact result', result)
		// 			this.contact = result.data
		// 			this.balance = this.contact.CustomerInfos[0].account
		// 			this.setContact(this.contact);
		// 			this.setBalance(this.balance)
		// 		})
		// },
		color(val) {
			let value = null
			switch (val) {
				case 'paid':
					value = 'green'
					break;
				case 'pending':
					value = 'yellow accent-4'
					break;
				case 'open':
					value = 'red'
					break;
				case 'closed':
					value = 'green'
					break;
				case 'rejected':
					value = 'red accent-4'
					break;
				// default:
				// value = 'grey'
				// 	break;
			}
			// console.log('value', value, val)
			return value
		},
		reloadtab() {
			// this.loadContactData()
			if (this.tab === 0) {
				EventBus.$emit('reload-activation-list')
				EventBus.$emit('reload-contact-information')
			} else if (this.tab === 1) {
				EventBus.$emit('reload-billPayment-list')
			} else {
				EventBus.$emit('reload-task-list')
			}
		}
	}
} 
</script>

<style scoped>
.center {
	display: flex;
	justify-content: center;
	height: 100% !important;
	color: white;
}

.touch-view-container {
	height: calc(100vh - 300px);
	width: 100%;
	overflow: auto;
}
</style>