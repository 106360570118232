<template>
  <div>
    <v-card width="200" v-if="!$vuetify.breakpoint.xsOnly" @click="dialog = !dialog" height="200"
      class="button-30 rounded-xl" :ripple="false">
      <v-card-text style="height: 100%">
        <v-layout column fill-height align-center>
          <v-flex shrink>
            <v-img height="100" contain src="@/assets/dual.png"></v-img>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex class="text-h6 font-weight-bold black--text">
            Sim Swap
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
    <v-card v-else width="100%" @click="dialog = !dialog" :ripple="false" height="85" class="button-30 rounded-xl">
      <v-card-text style="height: 100%">
        <v-row align="center">
          <v-col class="shrink">
            <v-img width="50" contain src="@/assets/dual.png"></v-img>
          </v-col>
          <v-col>
            <span class="font-weight-bold black--text">Sim Swap</span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialog" width="500" persistent>
      <!-- <template v-slot:activator="{ on, attrs }">
        
      </template> -->

      <v-card class="rounded-xl" elevation="15">
        <v-card-title class="text-h5 font-weight-bold black--text">
          Sim Swap
          <v-spacer></v-spacer>
          <v-btn text icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>


        <v-card-text>
          <v-row class="mt-4">
            <v-col cols="6">
              <v-text-field dense autofocus @keyup.enter="$refs.pinNumber.focus" outlined v-model="phoneNumber"
                @click:clear="clearPhoneNumber()" clearable 
                :error-messages="phoneNumberErrors" :hide-details="phoneNumberErrors.length === 0" label="Phone Number">
                <template v-slot:append>
                  <!-- <v-fade-transition leave-absolute> -->

                  <v-progress-circular v-if="phoneLoading" size="24" color="primary" indeterminate>
                  </v-progress-circular>
                  <div v-else>
                    <v-icon color="green" v-if="phoneFound === 'found'">mdi-check-circle</v-icon>
                    <v-icon color="red" v-if="phoneFound === 'notfound'">mdi-close-circle</v-icon>
                  </div>

                  <!-- </v-fade-transition> -->
                </template>

              </v-text-field>
              <div v-if="phoneFound === 'notfound'">{{ phoneErrorMessage }}</div>
            </v-col>
            <v-col cols="6">
              <v-text-field @keyup.enter="$refs.simcard.focus" ref="pinNumber" label="Pin Number" v-model="pinNumber"
                clearable dense outlined :error-messages="pinNumberErrors" :hide-details="pinNumberErrors.length === 0">
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-card outlined>
                <v-card-title primary-title>
                  New Sim Information
                </v-card-title>
                <v-card-text>
                  <v-tabs fixed-tabs v-model="tab">
                    <v-tab>Sim Card</v-tab>
                    <v-tab>E-Sim</v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="tab" class="mb-5">
                    <v-tab-item>
                      <v-row class="mt-4">
                        <v-col cols="12">
                          <v-text-field @focus="$event.target.select()" ref="simcard" dense outlined v-model="simcard"
                            @click:clear="clearable()" clearable 
                            :error-messages="simCardErrors" :hide-details="simCardErrors.length === 0"
                            label="Sim Number">
                            <template v-slot:append>
                              <!-- <v-fade-transition leave-absolute> -->

                              <v-progress-circular v-if="simLoading" size="24" color="primary" indeterminate>
                              </v-progress-circular>
                              <div v-else>
                                <v-icon color="green" v-if="simFound === 'found'">mdi-check-circle</v-icon>
                                <v-icon color="red" v-if="simFound === 'notfound'">mdi-close-circle</v-icon>
                              </div>

                              <!-- </v-fade-transition> -->
                            </template>

                          </v-text-field>
                          <div v-if="simFound === 'notfound'">{{ errorMessage }}</div>
                        </v-col>

                      </v-row>
                    </v-tab-item>
                    <v-tab-item>
                      <v-row class="mt-4">
                        <v-col cols="12">
                          <v-text-field dense :error-messages="imeiNumberErrors"
                            :hide-details="imeiNumberErrors.length === 0" outlined v-model="imeiNumber"
                            @click:clear="clearable()" clearable label="IMEI Number">

                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                  </v-tabs-items>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row>




            <!-- <v-col cols="12">
              <v-select :items="Plans" label="Select Plan" v-model="selectedPlan" dense :loading="loadingPlan"
                :error-messag
                es="selectPlanErrors" :hide-details="selectPlanErrors.length === 0" outlined></v-select>
            </v-col> -->




          </v-row>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <!-- <v-spacer></v-spacer> -->
          <v-btn height="45" block :loading="loading" @click="submit" color="primary">Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>
import taskService from "@/modules/Tasks/service.js";
import homeService from "@/modules/Home/service.js";
import EventBus from '@/event-bus.js';
import { mapActions, mapGetters } from 'vuex';
const { validationMixin } = require("vuelidate");
const { required, minLength, requiredIf } = require("vuelidate/lib/validators");
export default {
  name: 'new-portIn',
  data() {
    return {
      tab: 0,
      imeiNumber: null,
      phoneNumber: null,
      simInformation: null,
      phoneInformation: null,
      errorMessage: null,
      phoneErrorMessage: null,
      dialog: false,
      simLoading: false,
      phoneLoading: false,
      doneTypingInterval: 300,
      simFound: false,
      phoneFound: false,
      pinNumber: null,
      loading: false,
      simcard: null,
    }
  },
  computed: {
    ...mapGetters("global", ["getContactNumber", "getContact"]),
    imeiNumberErrors() {
      const errors = [];
      if (this.$v.imeiNumber) {
        if (!this.$v.imeiNumber.$dirty) return errors;
        if (!this.$v.imeiNumber.required) errors.push("Imei number is required");
        if (!this.$v.imeiNumber.minLength)
          errors.push("Number must be atleast 14 digits");
      }

      return errors;
    },
    phoneNumberErrors() {
      const errors = [];
      if (!this.$v.phoneNumber.$dirty) return errors;
      if (!this.$v.phoneNumber.required) errors.push("Phone Number number is required");
      if (!this.$v.phoneNumber.minLength)
        errors.push("Number must be atleast 10 digits");
      return errors;
    },
    simCardErrors() {
      const errors = [];
      if (this.$v.simcard) {
        if (!this.$v.simcard.$dirty) return errors;
        if (!this.$v.simcard.required) errors.push("Sim card number is required");
        if (!this.$v.simcard.minLength)
          errors.push("Number must be atleast 14 digits");
      }
      return errors;
    },
    pinNumberErrors() {
      const errors = [];
      if (!this.$v.pinNumber.$dirty) return errors;
      if (!this.$v.pinNumber.required) errors.push("Pin number is required");
      if (!this.$v.pinNumber.minLength)
        errors.push("Pin Number must be atleast 6 digits");
      return errors;
    },
  },
  // watch: {
  //   dialog(val) {
  //     if (val) {
  //       this.getPlan();
  //     }
  //   }
  // },
  watch: {
    phoneNumber(val) {
      if (val && val.length === 10) {
        this.searchPhoneNumber()
      }
    },
    simcard(val) {
      if (val && val.length > 19) {
        this.searchsim()
      }
    },
  },
  mixins: [validationMixin],
  validations() {
    let valid = {
      phoneNumber: {
        required,
        minLength: minLength(10),
      },
      pinNumber: {
        required,
        minLength: minLength(6),
      },
    }
    if (this.tab === 0) {
      valid.simcard = {
        required,
        minLength: minLength(14),
      }
    } else {
      valid.imeiNumber = {
        required,
        minLength: minLength(15),
      }
    }
    return valid
  },
  methods: {
    ...mapActions('global', ['setRandomNumber']),
    close() {
      this.empty()
      this.dialog = false
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$toast.open({
          message: 'Require fields missing',
          dismissible: true,
          type: 'error',
          position: 'top-right',
        });
        return false;
      }
      if (this.phoneFound === "notfound") {
        this.$toast.open({
          message: 'Phone number not found.',
          dismissible: true,
          type: 'error',
          position: 'top-right',
        });
        return false
      }

      if (this.simFound === "notfound") {
        this.$toast.open({
          message: 'Sim number not found.',
          dismissible: true,
          type: 'error',
          position: 'top-right',
        });
        return false
      }
      this.loading = true;

      const data = {
        phoneNumber: this.phoneNumber,
        title: 'Sim Swap Request',
        metadata: {
          esim: false,
          pinNumber: this.pinNumber,
          simNumber: this.simcard,
          imeiNumber: this.imeiNumber
        },
        type: 'Sim Swap',
        status: 'open'
      };

      if (this.imeiNumber) {
        data.metadata.esim = true
      }
      console.log('data', data)

      
      return taskService.create(data)
        .then(result => {
          console.log('reuslt', result)
          this.$toast.open({
            message: 'Request submitted',
            dismissible: true,
            type: 'success',
            position: 'top-right',
          });
          this.empty()
          this.loading = false
          this.dialog = false
          EventBus.$emit('reload-task-list')
        }).catch(err => {
          this.loading = false
        })


    },
    empty() {
      // tab: 0,
      // imeiNumber: null,
      // phoneNumber: null,
      // simInformation: null,
      // phoneInformation: null,
      // errorMessage: null,
      // phoneErrorMessage: null,
      // dialog: false,
      // simLoading: false,
      // phoneLoading: false,
      // doneTypingInterval: 300,
      // simFound: false,
      // phoneFound: false,
      // pinNumber: null,
      // loading: false,
      // simcard: null,
      this.phoneNumber = null,
      this.simInformation = null
      this.errorMessage = null
      this.simLoading = false
      this.phoneLoading = false
      this.phoneInformation = null
      this.simFound = false
      this.phoneFound = false
      this.pinNumber = null
      this.loading = false
      this.simcard = null
      this.$v.$reset();
    },
    clearPhoneNumber() {
      console.log('clearable pressed')
      this.phoneNumber = null
      this.phoneErrorMessage = null
    },
    clearable() {
      console.log('clearable pressed')
      this.simNumber = null
      this.errorMessage = null
    },
    searchsim() {
      this.simLoading = true;
      let newsim = this.simcard;
      const lastSimNumber = this.simcard.slice(-1);
      // console.log("lastSimNumber", lastSimNumber);
      if (lastSimNumber === "f" || lastSimNumber === "F") {
      } else {
        newsim += "F";
      }
      return homeService
        .getSimInfo(newsim)
        .then((response) => {
          console.log("response.data.status", response);
          if (
            response.data.status === "activated" ||
            response.data.status === "activate"
          ) {
            this.errorMessage =
              "Invalid sim number";
            this.simLoading = false;
            this.simFound = "notfound";
            return false;
          }
          this.simLoading = false;
          this.simFound = "found";
          this.simInformation = response.data;
        })
        .catch((error) => {
          this.simLoading = false;
          this.simFound = "notfound";
          this.errorMessage =
            "Invalid sim number.";
          // this.$swal(
          //   "Sim Status",
          //   "Invalid sim number please check sim number and try again.",
          //   "error"
          // );
        });
    },
    searchPhoneNumber() {
      this.phoneLoading = true;
      console.log('test')
      return taskService
        .searchPhoneNumber({ phoneNumber: this.phoneNumber })
        .then((response) => {
          console.log("response.data.status", response);
          if (response.data.length === 0) {
            console.log('not found')
            this.phoneErrorMessage =
              "Phone number not found.";
            this.phoneLoading = false;
            this.phoneFound = "notfound";
            return false;
          }
          this.phoneLoading = false;
          this.phoneFound = "found";
          this.phoneInformation = response.data;
        })
        .catch((error) => {
          this.phoneLoading = false;
          this.phoneFound = "notfound";
          this.phoneErrorMessage =
            "Phone number not found.";
          // this.$swal(
          //   "Sim Status",
          //   "Invalid sim number please check sim number and try again.",
          //   "error"
          // );
        });
    },
    // keyup() {
    //   clearTimeout(this.typingTimer);
    //   this.typingTimer = setTimeout(() => {
    //     this.searchPhoneNumber();
    //   }, this.doneTypingInterval);
    // },
    // keydown() {
    //   clearTimeout(this.typingTimer);
    // },
    // simcardKeyup() {
    //   clearTimeout(this.typingTimer);
    //   this.typingTimer = setTimeout(() => {
    //     this.searchsim();
    //   }, this.doneTypingInterval);
    // },
    // simcardKeydown() {
    //   clearTimeout(this.typingTimer);
    // },
  }
}
</script>

<style scoped>
/* CSS */
</style>