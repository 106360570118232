<template>
  <div>
    <v-card width="200" @click="dialog = !dialog" v-if="!$vuetify.breakpoint.xsOnly" :ripple="false" height="200"
      class="button-30 rounded-xl ">
      <v-card-text style="height: 100%">
        <v-layout column fill-height align-center>
          <v-flex shrink>
            <v-img height="100" contain src="@/assets/sim2.png"></v-img>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex class="text-h6 black--text font-weight-bold">
            New Number
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
    <v-card v-else width="100%" @click="dialog = !dialog" :ripple="false" height="85" class="button-30 rounded-xl">
      <v-card-text style="height: 100%">
        <v-row align="center">
          <v-col class="shrink">
            <v-img width="50" contain src="@/assets/sim2.png"></v-img>
          </v-col>
          <v-col>
            <span class="font-weight-bold black--text">New Number</span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialog" width="500" persistent>
      <!-- <template v-slot:activator="{ on, attrs }">
        
      </template> -->

      <v-card class="rounded-xl" elevation="15">
        <v-card-title class="text-h5 black--text font-weight-bold">
          New Number
          <v-spacer></v-spacer>
          <v-btn text icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-tabs fixed-tabs v-model="tab">
            <v-tab>Sim Card</v-tab>
            <v-tab>E-Sim</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" class="mb-5">
            <v-tab-item>
              <v-row class="mt-4">
                <v-col cols="12">
                  <v-text-field dense outlined v-model="simcard" @click:clear="clearable()" clearable
                    :error-messages="simCardErrors" :hide-details="simCardErrors.length === 0" autofocus
                    label="Sim Number">
                    <template v-slot:append>
                      <!-- <v-fade-transition leave-absolute> -->

                      <v-progress-circular v-if="simLoading" size="24" color="primary" indeterminate>
                      </v-progress-circular>
                      <div v-else>
                        <v-icon color="green" v-if="simFound === 'found'">mdi-check-circle</v-icon>
                        <v-icon color="red" v-if="simFound === 'notfound'">mdi-close-circle</v-icon>
                      </div>

                      <!-- </v-fade-transition> -->
                    </template>

                  </v-text-field>
                  <div v-if="simFound === 'notfound'">{{ errorMessage }}</div>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-card mt-3>
                <v-card-text>
                  <v-tabs v-model="phoneTypeTab">
                    <v-tab>Iphone</v-tab>
                    <v-tab>Samsung</v-tab>
                    <v-tab-item>
                      <v-row class="mt-4">
                        <v-col cols="12">
                          <v-text-field dense counter :error-messages="imeiESimIphoneErrors" outlined v-model="imeiNumber"
                            @click:clear="clearable()" clearable label="Iphone IMEI Number">
                            <template v-slot:append>
                              <v-progress-circular v-if="simLoading" size="24" color="primary" indeterminate>
                              </v-progress-circular>
                              <div v-else>
                                <v-icon color="green" v-if="simFound === 'notExist'">mdi-check-circle</v-icon>
                                <v-icon color="red" v-if="simFound === 'exist'">mdi-close-circle</v-icon>
                              </div>
                            </template>
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                    <v-tab-item>
                      <v-row class="mt-4">
                        <v-col cols="12">
                          <v-text-field dense counter :error-messages="imeiESimSamsungErrors" outlined
                            v-model="imeiNumber" @click:clear="clearable()" clearable label="Samsung EID Number">

                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                  </v-tabs>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
          <v-row>


            <v-col v-if="!selectedLine" cols="8">
              <v-select :items="Plans" label="Select Plan" v-model="selectedPlan" dense :loading="loadingPlan"
                :error-messages="selectPlanErrors" :hide-details="selectPlanErrors.length === 0" outlined>
              </v-select>
            </v-col>
            <v-col cols="4">
              <v-text-field label="Pin Number" v-model="pinNumber" clearable dense outlined
                :error-messages="pinNumberErrors" :hide-details="pinNumberErrors.length === 0"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field label="Area Code (Optional)" v-model="areaCode" clearable dense outlined></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="Add On (Optional)" v-model="addon" clearable dense outlined></v-text-field>
            </v-col>
          </v-row>



        </v-card-text>



        <v-divider></v-divider>

        <v-card-actions>
          <!-- <v-spacer></v-spacer> -->
          <v-btn height="45" block v-if="tab === 0" class="rounded-lg" :disabled="simInformation ? false : true"
            :loading="loading" @click="submit" color="primary">Submit
          </v-btn>
          <v-btn height="45" block v-if="tab === 1" class="rounded-lg" :disabled="imieInformation" :loading="loading"
            @click="submit" color="primary">Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import homeService from "@/modules/Home/service.js";
import { mapActions, mapGetters } from 'vuex';
const { validationMixin } = require("vuelidate");
import EventBus from '@/event-bus.js';
const { required, minLength, maxLength } = require("vuelidate/lib/validators");
export default {
  name: 'new-activation',
  props: ['inner', 'selectedLine'],
  data() {
    return {
      tab: 0,
      imeiNumber: null,
      simInformation: null,
      areaCode: null,
      doneTypingInterval: 1500,
      addon: null,
      errorMessage: null,
      dialog: false,
      simLoading: false,
      simFound: false,
      pinNumber: null,
      loading: false,
      simcard: null,
      loadingPlan: true,
      accountPin: null,
      Plans: [],
      selectedPlan: null,
      phoneTypeTab: 0,
      phoneType: 'iphone'
    }
  },
  computed: {
    ...mapGetters("global", ["getContactNumber", "getContact"]),
    imieInformation() {
      let value = true

      if (
        this.imeiNumber &&
        this.pinNumber &&
        this.selectedPlan &&
        this.simFound === 'notExist'
      ) {
        value = false
      }

      return value
    },
    simCardErrors() {
      const errors = [];
      if (this.$v.simcard) {
        if (!this.$v.simcard.$dirty) return errors;
        if (!this.$v.simcard.required) errors.push("Sim card number is required");
        if (!this.$v.simcard.minLength)
          errors.push("Number must be atleast 14 digits");
      }
      return errors;
    },
    imeiNumberErrors() {
      const errors = [];
      if (this.$v.imeiNumber) {
        if (!this.$v.imeiNumber.$dirty) return errors;
        if (!this.$v.imeiNumber.required) errors.push("Imei number is required");
        if (!this.$v.imeiNumber.minLength)
          errors.push("IMEI number must be 15 digits.");
        if (!this.$v.imeiNumber.maxLength)
          errors.push("IMEI number must be 15 digits.");
      }

      return errors;
    },
    imeiESimIphoneErrors() {
      const errors = [];
      if (this.$v.imeiNumber) {
        if (!this.$v.imeiNumber.$dirty) return errors;
        if (!this.$v.imeiNumber.required) errors.push("Imei number is required");
        if (!this.$v.imeiNumber.minLength)
          errors.push("IMEI number must be 15 digits.");
        if (!this.$v.imeiNumber.maxLength)
          errors.push("IMEI number must be 15 digits.");
      }

      return errors;
    },
    imeiESimSamsungErrors() {
      const errors = [];
      if (this.$v.imeiNumber) {
        if (!this.$v.imeiNumber.$dirty) return errors;
        if (!this.$v.imeiNumber.required) errors.push("Imei number is required");
        if (!this.$v.imeiNumber.minLength)
          errors.push("IMEI number must be 20 digits.");
        if (!this.$v.imeiNumber.maxLength)
          errors.push("IMEI number must be 20 digits.");
      }

      return errors;
    },
    pinNumberErrors() {
      const errors = [];
      if (!this.$v.pinNumber.$dirty) return errors;
      if (!this.$v.pinNumber.required) errors.push("Pin number is required");
      if (!this.$v.pinNumber.minLength)
        errors.push("Pin number must be atleast 6 digits");
      return errors;
    },
    selectPlanErrors() {
      const errors = [];
      if (!this.$v.selectedPlan.$dirty) return errors;
      if (!this.$v.selectedPlan.required)
        errors.push("Select plan is required");
      return errors;
    },
  },
  watch: {
    phoneTypeTab(val) {
      if (val === 0) this.phoneType = 'iphone';
      else this.phoneType = 'samsung';
    },
    simcard(val) {
      if (val && val.length > 19) {
        this.searchsim()
      }
    },
    imeiNumber(val) {
      if (this.phoneTypeTab === 0 && val?.length === 15) {
        return this.searchEsim()
      }
      if (this.phoneTypeTab === 1 && (val.length === 20 || val.length === 32)) {
        return this.searchEsim()
      }
    },
    dialog(val) {
      if (val) {
        this.$emit('closeSelection')
        this.getPlan();
      }
    }
  },
  mixins: [validationMixin],
  validations() {
    let validObj = {
      pinNumber: {
        required,
        minLength: minLength(6),
      },
    }

    if (!this.selectedLine) {
      validObj.selectedPlan = {
        required,
      }
    }

    if (this.tab === 0) {
      validObj.simcard = {
        required,
        minLength: minLength(14),
      }
    } else {
      if (this.phoneTypeTab === 0)
        validObj.imeiNumber = {
          required,
          minLength: minLength(15),
          maxLength: maxLength(15),
        }
      else
        validObj.imeiNumber = {
          required,
          minLength: minLength(32),
          maxLength: maxLength(32),
        }
    }

    return validObj

  },
  methods: {
    ...mapActions('global', ['setRandomNumber']),
    empty() {
      this.simInformation = null
      this.imeiNumber = null
      this.areaCode = null
      this.addon = null
      this.errorMessage = null
      this.simLoading = false
      this.simFound = false
      this.pinNumber = null
      this.loading = false
      this.simcard = null
      this.loadingPlan = true
      this.accountPin = null
      this.selectedPlan = null
      this.tab = 0
      this.phoneTypeTab = 0
      this.phoneType = 'iphone'
      this.$v.$reset();
    },
    close() {
      this.empty()
      this.dialog = false
    },
    submit() {
    // console.log('test', this.$v)
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$toast.open({
          message: 'Require fields missing',
          dismissible: true,
          type: 'error',
          position: 'top-right',
        });

        return false;
      }
      this.loading = true;

      let data = {
        metadata: {
          simInfo: {
            createdAt: new Date(),
            plan: this.selectedPlan,
            pinNumber: this.pinNumber,
            activation: 'New Activation',
            Contact: this.getContact,
            areaCode: this.areaCode,
            addon: this.addon,
          },
        },
      };
      data.siteAddress = 'Prepay Activation'
      if (this.tab === 1) {
        data.metadata.simInfo.eSim = true
        data.metadata.simInfo.phoneType = this.phoneType
        data.serialNumber = this.imeiNumber
        data.eSim = true
      } else {
        data.serialNumber = this.simInformation.serialNumber
      }


      
      if (this.selectedLine) {
        data.metadata.simInfo.plan = `${this.selectedLine.name} $${this.selectedLine.amount}`
        this.$emit('submit', { data, ...this.selectedLine })
        this.activationSend = true;
        this.loading = false;
        this.dialog = false
        this.empty()
      } else {
        this.markActive(data);
      }

    },
    markActive(data) {
      return homeService
        .markActive(data)
        .then((response) => {
          // console.log("markActive response", response);
          this.activationSend = true;
          this.loading = false;
          this.dialog = false
          let contact = JSON.parse(localStorage.getItem(
            `${process.env.VUE_APP_STORAGE_PREFIX}contact`
          ))
          // homeService.emitCustomEvent({
          //   data: {
          //     ...data,
          //     Contact: contact,
          //     type: 'New Activation'
          //   },
          //   event: 'SIMACTIVATEDFROMPORTAL'
          // 
          this.$toast.open({
            message: 'Request submitted',
            dismissible: true,
            type: 'success',
            position: 'top-right',
          });
          // this.$swal(
          //   "Activation Created!",
          //   `Your Activation is created. Please wait for the action to be taken.`,
          //   "success"
          // );
          EventBus.$emit('reload-activation-list')
          this.empty()
        })
        .catch((error) => {
          console.log("error", error);

        });
    },
    getPlan() {
      this.loadingPlan = true;
      return homeService
        .getSettings()
        .then((response) => {
          console.log("setting response", response);
          this.loadingPlan = false;
          this.Plans = response.plan;
          this.setting = response.setting;
        })
        .catch((error) => {
          console.log("error", error);
          this.loadingPlan = false;
          // this.$swal(
          //   "Sim Status",
          //   "Invalid sim number please check sim number and try again.",
          //   "error"
          // );
        });
    },
    clearable() {
      console.log('clearable pressed')
      this.simcard = null
      this.errorMessage = null
    },
    searchsim() {
      this.simLoading = true;
      let newsim = this.simcard;
      const lastSimNumber = this.simcard.slice(-1);
      // console.log("lastSimNumber", lastSimNumber);
      if (lastSimNumber === "f" || lastSimNumber === "F") {
      } else {
        newsim += "F";
      }
      return homeService
        .getSimInfo(newsim)
        .then((response) => {
          console.log("response.data.status", response);
          if (
            response.data.status === "activated" ||
            response.data.status === "activate"
          ) {
            this.errorMessage =
              "This sim is already activated. Check the sim number and try again";
            this.simLoading = false;
            this.simFound = "notfound";
            return false;
          }
          this.simLoading = false;
          this.simFound = "found";
          this.simInformation = response.data;
        })
        .catch((error) => {
          this.simLoading = false;
          this.simFound = "notfound";
          this.errorMessage =
            "Invalid sim number.";
          // this.$swal(
          //   "Sim Status",
          //   "Invalid sim number please check sim number and try again.",
          //   "error"
          // );
        });
    },
    searchEsim() {
      this.simLoading = true;
      let newsim = this.imeiNumber;
      return homeService
        .getSimInfo(newsim, {
          esim: true
        })
        .then((response) => {
          console.log("response.data.status", response);
          if (
            response.data?.id
          ) {
            this.errorMessage =
              "This Esim is already exist. Check the sim number and try again";
            this.simLoading = false;
            this.simFound = "exist";
            return false;
          }
          this.simLoading = false;
          this.simFound = "notExist";
          this.simInformation = response.data;
        })
        .catch((error) => {
          this.simLoading = false;
          this.simFound = "notExist";
        });
    },
    // keyup() {
    //   clearTimeout(this.typingTimer);
    //   this.typingTimer = setTimeout(() => {
    //     this.searchsim();
    //   }, this.doneTypingInterval);
    // },
    // keydown() {
    //   clearTimeout(this.typingTimer);
    // },
  }
}
</script>


