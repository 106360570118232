<template>
  <div>
    <v-card width="200" @click="dialog = !dialog" :ripple="false" height="200" class="button-30 rounded-xl"
      v-if="!$vuetify.breakpoint.xsOnly">
      <v-card-text style="height: 100%">
        <v-layout column fill-height align-center>
          <v-flex shrink>
            <v-img height="100" contain src="@/assets/carts.png"></v-img>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex class="text-h6 font-weight-bold black--text">Order</v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
    <v-card v-else width="100%" @click="dialog = !dialog" :ripple="false" height="85" class="button-30 rounded-xl">
      <v-card-text style="height: 100%">
        <v-layout row align-center>
          <v-flex shrink>
            <v-img width="50" contain src="@/assets/carts.png"></v-img>
          </v-flex>
          <v-flex>
            <span class="font-weight-bold black--text">Order</span>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialog" width="450" persistent>

      <v-card elevation="15" v-if="mainLoading" class="rounded-xl">
        <v-card-title class="text-h5">
          <span class="font-weight-bold black--text">Order</span>
          <v-spacer></v-spacer>
          <v-btn text icon @click="close">
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-container style="height: 400px;">
          <v-row class="fill-height" align-content="center" justify="center">
            <v-col class="text-subtitle-1 text-center" cols="12">
              Loading
            </v-col>
            <v-col cols="6">
              <v-progress-linear indeterminate rounded height="6"></v-progress-linear>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <div v-else>
        <v-card v-if="product" color="grey lighten-3" class="rounded-xl" elevation="15">
          <v-card-title class="text-h5 font-weight-bold black--text">
            Order
            <v-spacer></v-spacer>
            <v-btn text icon @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-divider></v-divider>


          <v-card-text>
            <v-row align="center" class="mt-2 px-5">
              <v-col>
                <h3 class="font-weight-regular text-h5 text-left">
                  {{ product.name }}
                </h3>
              </v-col>
              <!-- <h3 class="text-right">
                <div class="font-weight-regular text-h5">{{ price | currency }}</div>
                <div class="caption">each</div>
              </h3> -->
            </v-row>

            <!-- <v-row align="center" justify="center">
              <v-col cols="4" class="shrink">
                <div class="text-center">
                  <v-card width="100" @click="minusQty" :ripple="false" height="50" class="button-30 rounded-xl">
                    <v-icon height="50" color>mdi-minus</v-icon>
                  </v-card>
                </div>
              </v-col>
              <v-col cols="4" class="text-center mt-5 pt-5">
                <v-text-field solo class="al-c" v-model="quantity"></v-text-field>
                
              </v-col>
              <v-col cols="4">
                <div class="text-center">
                  <v-card width="100" @click="addQty" :ripple="false" height="50" class="button-30 rounded-xl">
                    <v-icon height="50">mdi-plus</v-icon>
                  </v-card>
                </div>
              </v-col>
            </v-row> -->
          </v-card-text>

          <v-row class="ma-5" justify="center">
            <v-col class="shrink">
              <input class="no-outline" ref="amountInput" v-model="quantity" placeholder="0" />
            </v-col>
          </v-row>

          <!-- <v-row>
            <v-col>
              <div class="text-center">Total Order</div>
              <div :class="{ 'animate__shakeY': runIt }" class="animate__animated font-weight-bold text-center">{{
                  this.price * this.quantity | currency
              }}</div>
            </v-col>
          </v-row> -->


          <v-card-actions class="pa-5">
            <v-row>
              <v-col cols="12">

                <v-btn rounded :disabled="quantity <= 0" block x-large :loading="loading" @click="submit"
                  color="primary">
                  {{ this.price * this.quantity | currency }} Place Order </v-btn>
              </v-col>
            </v-row>

          </v-card-actions>



          <!-- <v-divider></v-divider> -->

          <!-- <v-card-actions class="py-3">
            <v-row align="center">
              <v-col>
                <div>Total Order:

                  <div :class="{ 'animate__shakeY': runIt }" class="animate__animated font-weight-bold">
                    {{ this.price * this.quantity | currency }}</div>
                </div>
              </v-col>

              <v-col class="text-right">
                <v-btn class="rounded-xl" :disabled="quantity <= 0" :loading="loading" @click="submit" color="primary">
                  Submit</v-btn>
              </v-col>
            </v-row>
          </v-card-actions> -->
        </v-card>
        <v-card class="rounded-xl" v-else elevation="15">
          <v-card-title class="text-h5">
            Ordering
            <v-spacer></v-spacer>
            <v-btn text icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>


          <!-- <v-card-text>
            <div class="text-center">
              <v-img alt="coming soon" class="shrink text-center" contain src="@/assets/113096-coming-soon.gif" />
            </div>
            <div class="text-center mt-3">Contact your sales agent</div>
          </v-card-text> -->

        </v-card>
      </div>


    </v-dialog>
  </div>

</template>

<script>
import homeService from "@/modules/Home/service.js";
import orderService from "@/modules/Order/service.js";
import 'animate.css';
import { mapActions, mapGetters } from 'vuex';
const notificationSound = new Audio(require('../../../assets/audio/cellsim_notification_sound.wav'));


export default {
  name: 'new-activation',
  data() {
    return {
      runIt: false,
      dialog: null,
      loading: false,
      mainLoading: false,
      product: null,
      price: 0,
      quantity: 10,
      // invoice: {
      //   code: process.env.VUE_APP_LOCATION_CODE,
      //   CashDrawerId: null,
      //   EmployeeId: null,
      //   ContactId: null,
      //   closed: false,
      //   InvoiceDetails: [{
      //     InvoiceDetailTypeId: 9,
      //     // ProductId: 21292,
      //     cost: 10,
      //     price: 0,
      //     name: "test",
      //     discount: 0,
      //     surchargeTotal: 0,
      //     quantity: 10
      //   }]

      // }
    }
  },
  computed: {
    ...mapGetters("global", ["getContactNumber", "getContact"]),
  },
  // created() {
  //   let contact = JSON.parse(localStorage.getItem(
  //     `${process.env.VUE_APP_STORAGE_PREFIX}contact`
  //   ))
  //   this.invoice.ContactId = contact.id
  // },
  watch: {
    dialog(val) {
      if (val) {
        this.loadData();
        setTimeout(() => {
          this.$refs.amountInput.focus()
        }, 300);
      }
    }
  },
  methods: {
    ...mapActions('global', ['setRandomNumber']),
    getSettings() {
      this.mainLoading = true;
      return homeService
        .getSettings()
        .then((response) => {
          this.mainLoading = false;
          this.product = response.product;
          // this.invoice.InvoiceDetails[0].name = response.product.name;
          this.price = response.product.price;
          // this.invoice.CashDrawerId = response.CashDrawerId || 5
          // this.invoice.EmployeeId = response.EmployeeId
          this.loading = false
          // this.setting = response.setting;
        })
        .catch((error) => {
          console.log("error", error);
          this.mainLoading = false;
          // this.$swal(
          //   "Sim Status",
          //   "Invalid sim number please check sim number and try again.",
          //   "error"
          // );
        });
    },
    close() {
      this.price = 0
      this.quantity = 10
      this.product = null
      this.dialog = false
    },
    minusQty() {
      if (this.quantity > 10) {

        this.runIt = true
        setTimeout(() => {
          this.runIt = false
        }, 1000);
        this.quantity--;
      } else {
        this.$toast.open({
          message: 'Minimum order is 10!',
          dismissible: true,
          type: 'error',
          position: 'top-right',
        });
        //   this.$swal
        //     .mixin({
        //       toast: true,
        //       position: "top-end",
        //       timer: 3000,
        //     })
        //     .fire({
        //       type: "error",
        //       title: "Minimum order is 10",
        //     });
      }
    },
    addQty() {

      if (this.quantity < 100) {

        // if (this.runIt) {
        //   this.runIt = false
        // }
        this.runIt = true
        setTimeout(() => {
          this.runIt = false
        }, 400);
        this.quantity++

      };
    },
    loadData() {
      this.getSettings()
    },
    submit() {
      if (this.quantity < 10) {
        this.$toast.open({
          message: 'Minimum order is 10!',
          dismissible: true,
          type: 'error',
          position: 'top-right',
        });
        return false
      }
      this.loading = true;

      const data = {
        phoneNumber: 'N/A',
        title: `New Order for ${this.product.name}`,
        ContactId: this.getContact.id,
        amount: this.price * this.quantity,
        metadata: {
          quantity: this.quantity,
          name: this.product.name,
          price: this.price,
        },
        type: 'Order',
        status: 'open'
      };

      console.log('datadata', data)

      orderService.create(data)
        .then(response => {
          this.loading = false;
          this.dialog = false
          console.log('response is :', response);

          this.$toast.open({
            message: `Your order for ${this.quantity}x ${this.product.name} for ${this.quantity * this.price} has been created`,
            dismissible: true,
            type: 'success',
            position: 'top-right',
          });
          // this.$swal(
          //   "Order Created!",
          //   `Your order for ${this.invoice.InvoiceDetails[0].quantity}x ${this.invoice.InvoiceDetails[0].name} for ${this.invoice.InvoiceDetails[0].quantity * this.invoice.InvoiceDetails[0].price} has been created`,
          //   "success"
          // );
          // notificationSound.play();
        })
        .catch(error => {
          console.log('error', error)
          if (error.code = "100000") {
            this.$swal(
              "Order",
              'Please add an active payment method.',
              "error"
            );
          } else {
            this.$swal(
              "Order",
              error.data.message,
              "error"
            );
          }

          //   this.$toast.open({
          //   message: error.data.message,
          //   dismissible: true,
          //   type: 'error',
          //   position: 'top-right',
          // });
          this.loading = false
          // this.$swal()
        })
    },
  }
}
</script>

<style scoped>
.glassCard {
  /* From https://css.glass */
  background: rgba(225, 190, 231, 0.11) !important;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.2px);
  -webkit-backdrop-filter: blur(8.2px);
  border: 1px solid rgba(225, 190, 231, 0.68) !important;
}

/* CSS */
/* .centered-input input {
  text-align: center;
} */
.centered-input>>>input {
  text-align: center
}

.button-30 {
  align-items: center;
  appearance: none;
  background-color: #FCFCFD;
  border-radius: 4px;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
  box-sizing: border-box;
  color: #36395A;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono", monospace;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s, transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 18px;
}

.button-30:focus {
  box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
}

.button-30:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
  transform: translateY(-2px);
}

.button-30:active {
  box-shadow: #D6D6E7 0 3px 7px inset;
  transform: translateY(2px);
}

input {
  /* border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  background-color: #eee; */
  font-size: 72px;
  text-align: center;
  width: 220px;
  color: gray;
}

.no-outline:focus {
  outline: none;
  font-size: 72px;
}
</style>