<template>
  <v-dialog height="100%" v-model="dialog" fullscreen transition="dialog-bottom-transition" width="500"
    :overlay="false">
    <v-card elevation="15" color="grey lighten-2">
      <v-card-title class="text-h5">
        Receipt
        <v-spacer></v-spacer>
        <v-btn text icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <embed id="embedPDF" :src="pdf" type="application/pdf" class="desktop-view-container" width="100%" />
    </v-card>
  </v-dialog>
</template>

<script>
import pdfMake from 'pdfmake/build/pdfmake.min';
import pdfFonts from "pdfmake/build/vfs_fonts";
import { mapGetters } from 'vuex';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  name: 'receipt',
  props: ['openReceipt', 'receipt'],
  data() {
    return {
      dialog: false,
      pdf: null,
      doc: {
        info: {
          title: 'Receipt Preview',
        },
        content: [],
        styles: {
          locationInfo: {
            alignment: 'center',
            fontSize: 8,
          },
          heading: {
            alignment: 'center',
            fontSize: 10,
          },
          policy: {
            alignment: 'justify',
            fontSize: 6,
          },
          topDetails: {
            margin: [0, 0],
            fontSize: 8,
          },
          invoiceDetails: {
            margin: [0, 2],
            fontSize: 8,
          },
          totals: {
            margin: [0, 0],
            fontSize: 8,
          },
        },
        pageBreakBefore(
          currentNode,
          followingNodesOnPage,
          nodesOnNextPage,
          previousNodesOnPage,
        ) {
          return (
            currentNode.headlineLevel === 1 && followingNodesOnPage.length === 0
          );
        },
      },
    }
  },
  computed: {
    ...mapGetters('global', ['getBalance', 'getContact']),
    width() {
      const size = 80
      const base = size / 0.35277;
      const adjusted = base - (200 / base) * 20;
      return adjusted;
    },
    horizontalLine() {
      return {
        table: {
          widths: ['*'],
          body: [[''], ['']],
          drawLine: true,
        },
        layout: {
          hLineWidth(i, node) {
            return i === 0 || i === node.table.body.length ? 0 : 1;
          },
          vLineWidth(i, node) {
            return 0;
          },
        },
      };
    },
  },
  watch: {
    openReceipt(val) {
      this.dialog = val
      if (val) {
        this.createReceipt()
      }
    }
  },
  methods: {
    createReceipt() {
      this.doc.content = []
      this.doc.pageSize = {
        width: this.width,
        height: 'auto',
      };
      this.doc.pageMargins = [10, 10, 10, 10];
      this.doc.pageOrientation = 'horizontal';

      console.log('getContact', this.getContact, this.receipt)

      this.doc.content.push({
        text: this.getContact.businessName,
        fontSize: '12',
        bigFontSize: true,
        bold: true,
        alignment: 'center',
      });

      console.log('this.getContact is :', this.getContact)
      if (this.getContact.Addresses && this.getContact.Addresses[0]) {
        this.doc.content.push({
          text: this.getContact.Addresses[0].addressLine1,
          style: 'locationInfo',
        });
        if (this.getContact.Addresses[0].addressLine2) {
          this.doc.content.push({
            text: this.getContact.Addresses[0].addressLine2,
            style: 'locationInfo',
          });
        }

        this.doc.content.push({
          text: `${this.getContact.Addresses[0].city
            }, ${this.getContact.Addresses[0].State.code.substr(
              this.getContact.Addresses[0].State.code.indexOf('-') + 1,
            )} ${this.getContact.Addresses[0].postalCode}`,
          style: 'locationInfo',
        });
      }
      this.doc.content.push({
        text: this.getContact.phoneNumber,
        style: 'locationInfo',
      });
      this.doc.content.push({
        text: ' ',
      })
      this.doc.content.push({
        text: ' ',
      })


      this.doc.content.push({
        columns: [
          {
            text: 'Date',
            alignment: 'left',
          },
          {
            text: `${this.$moment(
              this.receipt.createdAt,
            ).format('LL')
              }`,
            alignment: 'right',
          },
        ],
        style: 'invoiceDetails',
      });
      this.doc.content.push({
        columns: [
          {
            text: 'Time',
            alignment: 'left',
          },
          {
            text: `${this.$moment(
              this.receipt.createdAt,
            ).format('LT')
              }`,
            alignment: 'right',
          },
        ],
        style: 'invoiceDetails',
      });


      this.doc.content.push({
        text: ' ',
      })
      this.doc.content.push({
        text: ' ',
      })


      this.doc.content.push({
        text: this.receipt.phoneNumber,
        fontSize: '20',
        bold: true,
        alignment: 'center',
      });

      this.doc.content.push({
        text: this.receipt.Carrier.name,
        fontSize: '12',
        bigFontSize: true,
        bold: true,
        alignment: 'center',
      });

      this.doc.content.push({
        text: ' ',
      })
      this.doc.content.push({
        text: ' ',
      })

      this.doc.content.push(this.horizontalLine);
      this.doc.content.push({
        columns: [
          {
            text: ' ',
            alignment: 'left',
          },
          {
            text: 'Amount',
            alignment: 'left',
          },
          {
            text: `${this.$options.filters.currency(this.receipt.amount)}`,
            alignment: 'right',
          },
        ],
        style: 'invoiceDetails',
      });

      this.doc.content.push({
        columns: [
          {
            text: ' ',
            alignment: 'left',
          },
          {
            text: 'Fee',
            alignment: 'left',
          },
          {
            text: `${this.$options.filters.currency(this.receipt.fee)}`,
            alignment: 'right',
          },
        ],
        style: 'invoiceDetails',
      });

      this.doc.content.push({
        columns: [
          {
            text: ' ',
            alignment: 'left',
          },
          {
            text: 'Total',
            alignment: 'left',
            bold: true,
          },
          {
            text: `${this.$options.filters.currency(parseFloat(this.receipt.fee) + parseFloat(this.receipt.amount))}`,
            alignment: 'right',
            bold: true,
          },
        ],
        style: 'invoiceDetails',
      });
      this.doc.content.push({
        text: ' ',
      })
      this.doc.content.push({
        text: ' ',
      })

      this.doc.content.push({
        text: 'Policy',
        fontSize: '10',
        bigFontSize: true,
        bold: true,
        alignment: 'center',
      });

      this.doc.content.push({
        text: ' ',
      })
      this.doc.content.push({
        text: `All sales are final. No exchange, refund or transfer.`,
        fontSize: '8',
        alignment: 'center',
      });

      pdfMake.createPdf(this.doc).getDataUrl((data) => {
        this.pdf = data;
      });


    },
    close() {
      this.$emit('close')
    },
  }
}
</script>

<style scoped>
.desktop-view-container {
  height: calc(100vh - 62px);
  width: 100%;
}
</style>